import { dateToApiFormat } from '@/helpers/dateHelper';
import { useCallback } from 'react';
import { useGetHolidaysQuery } from '../gql/graphql.generated';
import { useSystemSettings } from '../settings/SystemSettingsProvider';
import { DayOfWeekAndHoliday } from './holiday.types';

export interface Holiday {
  date: Date;
  name: string;
}

export const usePublicHolidays = (date: Date) => {
  const selectedYear = date.getUTCFullYear();
  const selectedRegion = useSystemSettings(s => s.settings.holidayRegion);

  const [{ data }] = useGetHolidaysQuery({
    variables: {
      start: `${selectedYear - 1}-01-01`,
      end: `${selectedYear + 1}-12-31`,
      countryCode: selectedRegion
    },
    pause: !selectedRegion
  });

  if (!selectedRegion) {
    return [] as Holiday[];
  }

  const holidays = data?.holidays?.holidays?.map(h => ({ date: new Date(h.date), name: h.name })) as Holiday[];

  if (!holidays) {
    throw Error('Could not get public holidays');
  }

  return holidays;
};

export const useGetPublicHoliday = (yearDate: Date) => {
  const holidays = usePublicHolidays(yearDate);

  return useCallback(
    (date: Date) => {
      return holidays.find(h => dateToApiFormat(h.date) === dateToApiFormat(date));
    },
    [holidays]
  );
};

export const useGetDayOfWeekAndHoliday = (yearDate: Date) => {
  const holidays = usePublicHolidays(yearDate);

  return useCallback(
    (date: Date) => {
      if (date.getUTCDay() == 0) {
        return DayOfWeekAndHoliday.SUNDAY; // Sunday is not considered as a holiday in terms of work pattern selection because the following day becomes a substitute holiday.
      }

      const isHoliday = holidays.find(h => dateToApiFormat(h.date) === dateToApiFormat(date)) !== undefined;
      return isHoliday ? DayOfWeekAndHoliday.HOLIDAY : (date.getUTCDay() as DayOfWeekAndHoliday);
    },
    [holidays]
  );
};

export const usePublicHoliday = (date: Date) => {
  const getHoliday = useGetPublicHoliday(date);

  return getHoliday(date);
};
