import { cn } from '@/lib/utils';
import { Loader } from '@softwareimaging/react';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center border rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-background text-primary-foreground hover:bg-background-mid/10',
        active: 'bg-active text-primary-foreground hover:bg-background-mid/10',
        destructive: 'bg-secondary text-white hover:bg-secondary/90',
        secondary: 'bg-secondary border-white text-white hover:bg-secondary/80',
        secondaryOutline: 'bg-white border-secondary text-secondary hover:bg-background-mid/10',
        primary: 'bg-primary text-white hover:bg-primary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 hover:underline text-primary',
        whiteGhost: 'text-primary bg-white border border-primary hover:bg-background-mid/10',
        submit: 'bg-on text-white'
      },
      size: {
        default: 'h-10 py-2 px-5',
        sm: 'py-1 px-5 rounded-md',
        lg: 'py-3 px-8 rounded-md'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, children, loading, ...props }, ref) => {
    return (
      <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {loading ? <Loader className="w-6 h-6" /> : children}
      </button>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };

