import { CalendarPeriodType } from '@/pages/layout/dateSelector.types';
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarYears,
  differenceInDays,
  differenceInMinutes,
  format,
  minutesToMilliseconds,
  minutesToSeconds,
  setDate,
  startOfDay,
  startOfToday,
  subDays
} from 'date-fns';
import { enUS, ja } from 'date-fns/locale';

export const timeStringToDate = (timeString: string) => {
  const splitString = timeString.split(':');
  const hours = parseInt(splitString[0]);
  const minutes = parseInt(splitString[1]);

  const date = new Date(0);
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);

  return date;
};

export const dateToTimeString = (date: Date) => {
  return date.toISOString().substring(11, 16);
};

export const dateToStandardString = (date: Date | string) => {
  return format(new Date(date), 'yyyy/MM/dd');
};

export const dateToSecondsSinceMidnight = (date: Date) => {
  return date.getUTCHours() * 60 * 60 + date.getUTCMinutes() * 60 + date.getUTCSeconds();
};

export const secondsSinceMidnightToTimeString = (seconds: number) => {
  return dateToTimeString(secondsSinceMidnightToDate(seconds));
};

export const secondsSinceMidnightToDate = (secondsSinceMidnight: number) => {
  const date = new Date(0);

  const hours = Math.floor(secondsSinceMidnight / 3600);
  const secondsSinceHour = secondsSinceMidnight - hours * 3600;
  const minutes = Math.floor(secondsSinceHour / 60);
  const secondsSinceMinute = secondsSinceHour - minutes * 60;

  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(secondsSinceMinute);

  return date;
};

export const timeStringToSecondsSinceMidnight = (timeString: string) => {
  const splitString = timeString.split(':');
  const hours = parseInt(splitString[0]);
  const minutes = parseInt(splitString[1]);
  return hoursToSeconds(hours) + minutesToSeconds(minutes);
};

export const calculateMinutesDuration = (start: Date, end: Date) => {
  return differenceInMinutes(Math.floor(stripSeconds(end)), Math.floor(stripSeconds(start)));
};

export const secondsToDate = (x: number) => {
  const date = new Date(0);
  date.setUTCHours(Math.floor(x / 3600), Math.floor((x % 3600) / 60), x % 60);
  return date;
};

const numberToPadded = (number: number) => {
  let str: string;
  if (Math.abs(number) > 9) {
    str = Math.abs(number).toString();
  } else {
    str = `0${Math.abs(number)}`;
  }

  if (number < 0) {
    str = `-${str}`;
  }

  return str;
};

export const secondsToHours = (seconds: number) => {
  return seconds / 60 / 60;
};

export const hoursToSeconds = (hours: number) => {
  return hours * 60 * 60;
};

export const secondsToTimeString = (seconds: number, forceSign?: boolean) => {
  const absSeconds = Math.abs(seconds);
  const hours = Math.floor(absSeconds / (60 * 60));
  const minutes = Math.floor(absSeconds / 60) % 60;

  let timeString = `${forceSign ? hours : numberToPadded(hours)}:${numberToPadded(minutes)}`;

  if (seconds < 0) {
    timeString = `-${timeString}`;
  } else if (forceSign) {
    timeString = `+${timeString}`;
  }

  return timeString;
};

export const hoursToTimeString = (hours: number, forceSign?: boolean) => {
  return secondsToTimeString(hours * 60 * 60, forceSign);
};

export const isDateInPast = (dateOne: Date, dateTwo: Date) =>
  new Date(dateOne).setUTCHours(0, 0, 0, 0) < new Date(dateTwo).setUTCHours(0, 0, 0, 0);

export const convertDateToGQLFormat = (date: Date) => dateToApiFormat(date);

export const convertToUtcDate = (iso8601string?: string) => {
  if (iso8601string) {
    if (iso8601string.indexOf('+') > -1) {
      // timezone is already included, we don't handle this case for now, but have to avoid runtime error.
      return new Date(iso8601string);
    }
    // This is a UTC string. Add 'Z' if the string doesn't end with a 'Z'.
    return iso8601string.endsWith('Z') ? new Date(iso8601string) : new Date(iso8601string + 'Z');
  }
  return undefined;
};

export const createUTCDate = (year: number, month: number, day: number) => {
  return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
};

export const formatUTCDate = (date: Date) => {
  return createUTCDate(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

// All our dates are intended to be in UTC. This doesn't work for formatting, however.
// This takes a date which is correct in UTC, and fudges it to be correct when we format it.
export const createTimezoneCorrectedDate = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * minutesToMilliseconds(1));
};

export const printDateMonthAndYear = (date: Date, language?: string) => {
  const utcDate = createTimezoneCorrectedDate(date);
  const locale = language === 'ja' ? ja : enUS;

  return format(utcDate, 'MMMM yyyy', { locale });
};

export const printDateMonth = (date: Date, language?: string) => {
  const utcDate = createTimezoneCorrectedDate(date);
  const locale = language === 'ja' ? ja : enUS;

  return format(utcDate, 'MMM', { locale });
};

export const getDaysInMonth = (selectedDate: Date, startDay: number = 1): Date[] => {
  const startDate = formatUTCDate(selectedDate);

  if (selectedDate.getUTCDate() < startDay) {
    startDate.setUTCMonth(startDate.getUTCMonth() - 1);
  }
  startDate.setUTCDate(effectiveMonthStart(startDate, startDay));

  const daysInMonth: Date[] = [];
  let currentDate = new Date(startDate);

  while (
    currentDate.getUTCMonth() % 12 === startDate.getUTCMonth() % 12 ||
    (currentDate.getUTCMonth() % 12 === (startDate.getUTCMonth() + 1) % 12 &&
      currentDate.getUTCDate() < startDate.getUTCDate())
  ) {
    daysInMonth.push(new Date(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return daysInMonth;
};

export const getDaysInMonthCount = (date: Date) => {
  return getDaysInMonth(date).length;
};

export const getDaysInWeek = (startDate: Date): Date[] => {
  return Array.from({ length: 7 }, (_, i) =>
    createUTCDate(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() + i)
  );
};

export const getDaysInThreeMonths = (selectedDate: Date) => {
  const prev = new Date(selectedDate);
  prev.setUTCDate(0);

  const next = new Date(selectedDate);
  next.setUTCDate(32);

  const prevMonth = getDaysInMonth(prev);
  const currMonth = getDaysInMonth(selectedDate);
  const nextMonth = getDaysInMonth(next);

  return [...prevMonth, ...currMonth, ...nextMonth];
};

export const getDatePeriod = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];

  const dateToAdd = new Date(startDate);
  while (dateToAdd <= endDate) {
    dates.push(formatUTCDate(dateToAdd));
    dateToAdd.setUTCDate(dateToAdd.getUTCDate() + 1);
  }

  return dates;
};

export const isDateEqual = (dateOne: Date, dateTwo: Date) => {
  return (
    dateOne.getUTCFullYear() === dateTwo.getUTCFullYear() &&
    dateOne.getUTCMonth() === dateTwo.getUTCMonth() &&
    dateOne.getUTCDate() === dateTwo.getUTCDate()
  );
};

export const getStartAndEndDateOfMonth = (date: Date) => {
  const firstDay = createUTCDate(date.getUTCFullYear(), date.getUTCMonth(), 1);
  const lastDay = createUTCDate(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);

  return {
    startDate: firstDay,
    endDate: lastDay
  };
};

export const dateToApiFormat = (date: Date) => {
  const utcDate = formatUTCDate(date);

  return utcDate.toISOString().substring(0, 10);
};

export const dateToGQLFormat = (date: Date) => {
  return date.toISOString();
};

export const stripSeconds = (time: Date) => {
  return new Date(time).setUTCSeconds(0, 0);
};

export const compareDateToRosterDate = (date: Date, rosterDate: string) => {
  return rosterDate.substring(0, 19) === date.toISOString().substring(0, 19);
};

export const dayDiff = (startDate: number | Date, endDate: number | Date) => {
  return Math.abs(differenceInDays(startDate, endDate));
};

export const yearDiff = (startDate: number | Date, endDate: number | Date) => {
  return Math.abs(differenceInCalendarYears(startDate, endDate));
};

export const getEmployeeAge = (dob: string) => {
  return yearDiff(new Date(), new Date(dob));
};

export const isEmployeeUnderage = (dob: string) => {
  return yearDiff(new Date(), new Date(dob)) < 18;
};

/*
 * Lots of organisations want months to start on different days- eg. PAL wants the 16th.
 *
 * This causes a problem if an organisation picks an awkward date- eg. the 31st.
 *
 * If the start date doesn't exist for a month, then instead, use the last possible date.
 */
export const effectiveMonthStart = (selectedDate: Date, monthStart: number) => {
  if (
    selectedDate.getUTCMonth() === 8 ||
    selectedDate.getUTCMonth() === 3 ||
    selectedDate.getUTCMonth() === 5 ||
    selectedDate.getUTCMonth() === 10
  ) {
    return Math.min(30, monthStart);
  }

  if (selectedDate.getUTCMonth() === 1) {
    if (selectedDate.getUTCFullYear() % 4 !== 0) {
      // Definitely not a leap year.
      return Math.min(28, monthStart);
    }

    if (selectedDate.getUTCFullYear() % 400 === 0) {
      // Every 400th year is a leap year.
      return Math.min(29, monthStart);
    }

    if (selectedDate.getUTCFullYear() % 100 === 0) {
      // Other end of century years are not leap years- eg. 2100 won't be one.
      return Math.min(28, monthStart);
    }

    return Math.min(29, monthStart);
  }

  return Math.min(monthStart, 31);
};

const generateCalendarDates = (startDate: Date, endDate: Date): Date[][] => {
  const calendarRows: Date[][] = [];
  const dateToStart = startDate.getUTCDate();
  const daysDiff = differenceInDays(endDate, startDate);
  const daysForFullCalendarView = Math.ceil(daysDiff / 7) * 7;
  let row: Date[] = [];

  for (let index = 1; index <= daysForFullCalendarView; index++) {
    const newDate = startOfDay(startDate);
    const dateToPush = startOfDay(newDate.setUTCDate(dateToStart + index));
    row.push(dateToPush);

    if (index % 7 === 0) {
      calendarRows.push([...row]);
      row = [];
    }
  }
  return calendarRows;
};

const findWeekIndexInCalendarByGivenDate = (calendar: Date[][], dateToFind: Date): number => {
  return calendar.findIndex(week =>
    week.find(d => {
      const weekDate = startOfDay(d);
      const startDate = startOfDay(dateToFind);

      if (weekDate.getUTCDate() === startDate.getUTCDate() && weekDate.getUTCMonth() === startDate.getUTCMonth())
        return true;
      return false;
    })
  );
};

const extractStartingCalendarWeeks = (
  calendar: Date[][] = [],
  calendarStartDate: Date = startOfToday(),
  calendarEndDate?: Date
): Date[][] => {
  const incomingCalendarStartDate = startOfDay(calendar[0][0]);
  const neededCalendarStartDate = startOfDay(calendarStartDate);

  if (incomingCalendarStartDate > neededCalendarStartDate) {
    const daysDiff = differenceInDays(incomingCalendarStartDate, neededCalendarStartDate);
    const weeksToFill = Math.ceil(daysDiff / 7);
    const totalDays = weeksToFill * 7;
    const genStartDate = startOfDay(subDays(incomingCalendarStartDate, totalDays + 1));
    const genEndDate = startOfDay(subDays(incomingCalendarStartDate, 1));

    return generateCalendarDates(genStartDate, genEndDate);
  }

  const startWeekIndex = findWeekIndexInCalendarByGivenDate(calendar, calendarStartDate);

  let sliceEndIndex = calendar.length;

  if (calendarEndDate) {
    const endIndex = findWeekIndexInCalendarByGivenDate(calendar, calendarEndDate);

    if (endIndex > -1 && startWeekIndex < endIndex) {
      sliceEndIndex = endIndex;
    }
  }

  return calendar.slice(startWeekIndex, sliceEndIndex);
};

export const getCalendarStartDate = (currentlyViewingDate: Date, requestedStartDate: number): Date => {
  const endDateOfLastMonth = new Date(currentlyViewingDate);
  endDateOfLastMonth.setUTCDate(0);

  if (currentlyViewingDate.getUTCDate() >= requestedStartDate) {
    const dateToSet = new Date(currentlyViewingDate);
    dateToSet.setUTCDate(requestedStartDate);

    return dateToSet;
  } else if (endDateOfLastMonth.getUTCDate() >= requestedStartDate) {
    const dateToSet = new Date(endDateOfLastMonth);
    dateToSet.setUTCDate(requestedStartDate);

    return dateToSet;
  } else {
    return endDateOfLastMonth;
  }
};

export const getCalendarEndDate = (calendarStartDate: Date, requestedStartDate: number): Date => {
  // Find a date definitely in the next 'month' by going forwards 32 days
  const dateInNextMonth = new Date(calendarStartDate);
  dateInNextMonth.setUTCDate(dateInNextMonth.getUTCDate() + 32);

  // Find the start of the next month.
  const startOfNextMonth = getCalendarStartDate(dateInNextMonth, requestedStartDate);

  // Go back one day.
  const endOfThisMonth = new Date(startOfNextMonth);
  endOfThisMonth.setUTCDate(endOfThisMonth.getUTCDate() - 1);

  return endOfThisMonth;
};

// get calendar matrix based on given period
export const overrideDefaultCalendarByRangeCriteria = (
  calendar: Date[][][],
  calendarStartDate: Date,
  lastingFor: number = 1,
  lastingCriteria: CalendarPeriodType = CalendarPeriodType.MONTHS
): Date[][][] => {
  const startingCalendarData = extractStartingCalendarWeeks(calendar[0], calendarStartDate);
  const lastWeekDays = startingCalendarData[startingCalendarData.length - 1];
  const slicedLastDate = new Date(lastWeekDays[lastWeekDays.length - 1]);
  const setCalendarCurrentDate = setDate(calendarStartDate, calendarStartDate.getUTCDate());

  let calendarEndDate = calendarStartDate;

  switch (lastingCriteria) {
    case CalendarPeriodType.DAYS:
      calendarEndDate = addDays(setCalendarCurrentDate, lastingFor);
      break;

    case CalendarPeriodType.YEARS:
      calendarEndDate = subDays(addYears(setCalendarCurrentDate, lastingFor), 1);
      break;

    default:
      // default to Months
      calendarEndDate = subDays(addMonths(setCalendarCurrentDate, lastingFor), 1);
      break;
  }

  const startOfCalendarEndDate = startOfDay(calendarEndDate);

  const newCalendar = [...startingCalendarData, ...generateCalendarDates(slicedLastDate, startOfCalendarEndDate)];
  return [newCalendar];
};

// get default calendar matrix for 1 month based on the date we wish to start
export const overrideDefaultMonthlyCalendar = (
  calendar: Date[][][],
  currentlyViewingDate: Date,
  requestedStartDate: number
): Date[][][] => {
  const calendarStartDate = getCalendarStartDate(currentlyViewingDate, requestedStartDate);
  const calendarEndDate = getCalendarEndDate(calendarStartDate, requestedStartDate);
  const startingCalendarData = extractStartingCalendarWeeks(calendar[0], calendarStartDate, calendarEndDate);
  const lastWeekDays = startingCalendarData[startingCalendarData.length - 1];
  const slicedLastDate = startOfDay(lastWeekDays[lastWeekDays.length - 1]);

  const newCalendar = [...startingCalendarData, ...generateCalendarDates(slicedLastDate, calendarEndDate)];

  return [newCalendar];
};

export const getLastMonthDay = (dateToReverse: Date, monthDay: number) => {
  const date = new Date(dateToReverse);
  date.setUTCHours(0, 0, 0, 0);

  let dateFound = false;
  // Walk backwards until we find the right day, or a close enough approximation.
  while (!dateFound) {
    // First, we'll be walking back to the start of the month.
    // If we happen to hit the right day, then we're done.
    if (date.getUTCDate() == monthDay) {
      dateFound = true;
    }

    if (date.getUTCMonth() != dateToReverse.getUTCMonth()) {
      // We've stepped into the previous month.
      // We need to check if we've already passed the month day.
      // (eg. We were looking for a 31st, and stepped into February)
      if (date.getUTCDate() < monthDay) {
        dateFound = true;
      }
    }

    if (dateFound) {
      break;
    } else {
      date.setUTCDate(date.getUTCDate() - 1);
    }
  }

  return date;
};

export const getNextMonthDay = (dateToAdvance: Date, monthDay: number) => {
  const date = new Date(dateToAdvance);
  date.setUTCHours(0, 0, 0, 0);

  let dateFound = false;
  let previousDayChecked: Date | undefined = undefined;
  // Walk forwards until we find the right day, or a close enough approximation.
  while (!dateFound) {
    // First, we'll be walking ahead to the end of the month.
    // If we happen to hit the right day, then we're done.
    if (date.getUTCDate() == monthDay) {
      dateFound = true;
    }

    if (previousDayChecked && date.getUTCMonth() != dateToAdvance.getUTCMonth() && date.getUTCDate() === 1) {
      // We've stepped into the next month.
      // We need to check if we passed the month day
      // (eg. We were looking for a 31st, and stepped into March)
      if (monthDay === 0 || previousDayChecked.getUTCDate() < monthDay) {
        dateFound = true;

        // Because we missed the month day at the end of the last month,
        // take the last day of the month instead.
        date.setUTCDate(date.getUTCDate() - 1);
      }
    }

    if (dateFound) {
      break;
    } else {
      previousDayChecked = new Date(date);
      date.setUTCDate(date.getUTCDate() + 1);
    }
  }

  return date;
};

export const getStartDate = (startDay: number, selectedDate: string): Date => {
  const date = new Date(selectedDate);
  const selectedDay = date.getUTCDay();

  if (selectedDay > startDay) {
    date.setUTCDate(date.getUTCDate() - (selectedDay - startDay));
  } else if (selectedDay < startDay) {
    date.setUTCDate(date.getUTCDate() - (selectedDay - startDay) - 7);
  }
  return date;
};
