import { strings } from './strings';

export const displayName = (person: {
  firstName?: string;
  lastName?: string;
  kanjiFirstName?: string;
  kanjiLastName?: string;
}) => {

  // LastName and KanjiLastName can be undefined if the HR system cannot provide first and last names separately.
  // In this case FirstName and KanjiFirstName are full names.

  if (strings.getLanguage() === 'ja') {
    if (person.kanjiLastName && person.kanjiFirstName) {
      return `${person.kanjiLastName} ${person.kanjiFirstName}`;
    }
    else if (person.kanjiFirstName) {
      return person.kanjiFirstName;
    }
    else if (person.lastName && person.firstName) {
      return `${person.lastName} ${person.firstName}`;
    }
    else if (person.firstName) {
      return person.firstName;
    }
  }

  if (person.firstName && person.lastName) {
    return `${person.firstName} ${person.lastName}`;
  }
  return person.firstName ?? "";
};
