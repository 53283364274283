import { Announcement } from '@/pages/settings/sections/manageAnnouncements/Announcement';
import { hoursToSeconds } from 'date-fns';
import { Location, ReadEmployee } from '../gql/graphql.generated';
import { MonthlySortConfig } from '../sort/MonthlySortProvider';

export interface PersonnelDetailsSettings {
  dateOfBirth: boolean;
  hiredDate: boolean;
  leftCompanyDate: boolean;
  endProbationDate: boolean;
}

export interface RosterValidationSettings {
  managementAttendance: boolean;
  mandatorySkills: boolean;
}

export interface SplitShiftsSettings {
  maxNumber: number;
  minSecondsBetween: number;
}

export enum OrganisationNameType {
  COMPANY = 'Company',
  DIVISION = 'Division',
  BRAND = 'Brand',
  CUSTOM = 'Custom'
}

export interface OrganisationNameSettings {
  type: OrganisationNameType;
  customName: string;
}

export interface AvailabilityDeadlineSettings {
  sendReminders: boolean;
  deadlineMonthDay: number;
  notifyMonthDay: number;
  notifyHour: number;
}

export interface ReassignmentSettings {
  model: 'on-request' | 'pull' | 'disabled';
  workAtAllLocations: boolean;
}

export interface SystemSettings {
  monthStartDay: number;
  weekStartDay: number;
  futureAvailabilityMonths: number;
  includeBreaksInTotals: boolean;
  timeOffsetAfterLastBreak: number;
  timeOffsetBeforeFirstBreak: number;
  minuteIncrement: number;
  canDeleteRosters: boolean;
  displayEmployeeIds: boolean;
  editableDetails: PersonnelDetailsSettings;
  viewableDetails: PersonnelDetailsSettings;
  highlightSystemTabsColor: string;
  systemTabsTextColor: string;
  rosterValidation: RosterValidationSettings;
  optimizerDuration: number;
  optimizerManagementAttendance: boolean;
  maxTime: number;
  minTime: number;
  maxCustomTemplates: number;
  organisationName: OrganisationNameSettings;
  splitShifts: SplitShiftsSettings;
  availabilityDeadlineSettings: AvailabilityDeadlineSettings;
  holidayRegion: string;
  minShiftDuration: number;
  defaultShiftDuration: number;
  maxTimePeriods: number;
  advancedLeaveManagement: boolean;
  reassignment: ReassignmentSettings;
  allocateStaffButton: boolean;
}

const MIN_HOURS = 8;
const MAX_HOURS = 23;

export const defaultSettings: SystemSettings = {
  monthStartDay: 1,
  weekStartDay: 1,
  futureAvailabilityMonths: 6,
  includeBreaksInTotals: false,
  timeOffsetAfterLastBreak: 0,
  timeOffsetBeforeFirstBreak: 0,
  minuteIncrement: 15,
  canDeleteRosters: false,
  displayEmployeeIds: false,
  editableDetails: {
    dateOfBirth: true,
    hiredDate: true,
    leftCompanyDate: true,
    endProbationDate: true
  },
  viewableDetails: {
    dateOfBirth: true,
    hiredDate: true,
    leftCompanyDate: true,
    endProbationDate: true
  },
  highlightSystemTabsColor: '',
  systemTabsTextColor: '',
  rosterValidation: {
    managementAttendance: true,
    mandatorySkills: true
  },
  optimizerDuration: 30,
  optimizerManagementAttendance: true,
  maxTime: hoursToSeconds(MAX_HOURS),
  minTime: hoursToSeconds(MIN_HOURS),
  maxCustomTemplates: 10,
  organisationName: {
    type: OrganisationNameType.COMPANY,
    customName: ''
  },
  splitShifts: {
    maxNumber: 1,
    minSecondsBetween: 0
  },
  availabilityDeadlineSettings: {
    sendReminders: false,
    deadlineMonthDay: 1,
    notifyMonthDay: 1,
    notifyHour: 8
  },
  holidayRegion: '',
  minShiftDuration: 2,
  defaultShiftDuration: 2,
  maxTimePeriods: 1,
  advancedLeaveManagement: false,
  reassignment: {
    model: 'on-request',
    workAtAllLocations: false
  },
  allocateStaffButton: false
};

export interface StaffWithSettings {
  employee: ReadEmployee;
  settings: StaffSettings;
}

export interface StaffWithSettings {
  employee: ReadEmployee;
  settings: StaffSettings;
}

export interface StaffSettingsErrors {
  employeeId: string;
  errors: string[];
}

export interface StaffSettings {
  minMonthlyHours: number;
  maxMonthlyHours: number;
  minWeeklyHours: number;
  maxWeeklyHours: number;
  email: string;
  phoneNumber: string;
  workingPatterns: WorkingPatterns[];
  assignedSkills: string[];
  dateOfBirth?: Date;
  hiredDate?: Date;
  endProbationDate?: Date;
  leftCompanyDate?: Date;
  requiresMentor?: boolean;
  canMentor?: boolean;
  toBeDeleted?: boolean;
  hasBeenDeleted?: boolean;
  assignedPersonaIds: string[];
  assignedLocationIds: number[];
  notes?: string;
  preferences?: StaffPreferences;
}

export enum OpenClosingTimeOptions {
  hidden = 'HIDDEN',
  fieldsOnly = 'FIELDS',
  all = 'ALL'
}

export enum GanttGridLinesThickness {
  small = 'S',
  medium = 'M',
  large = 'L',
  extraLarge = 'XL'
}

export enum GanttGridLineSpacing {
  none = 0,
  tenMinutes = 10,
  fifteenMinutes = 15,
  thirtyMinutes = 30
}

export interface PersonalizationPreferences {
  ganttGridLines: GanttGridLineSpacing;
  ganttGridLinesThickness: GanttGridLinesThickness;
  openClosingTimes: OpenClosingTimeOptions;
}

export interface StaffPickerPrefernces {
  status: boolean;
  timeAvailable: boolean;
  allocation: boolean;
  role: boolean;
  holidays: boolean;
  attendances: boolean;
  workingHours: boolean;
  tasks: boolean;
  taskNames: boolean;
}

export interface StaffPreferences {
  staffPicker: StaffPickerPrefernces;
  taskDisplay: {
    [key: string]: boolean;
  };
  personalization: PersonalizationPreferences;
  monthlySortOrder: MonthlySortConfig[];
}

export const defaultStaffSettings: StaffSettings = {
  maxMonthlyHours: 0,
  minMonthlyHours: 0,
  minWeeklyHours: 0,
  maxWeeklyHours: 0,
  phoneNumber: '',
  email: '',
  workingPatterns: [
    { day: 0, startTime: 0, endTime: 0, id: '' },
    { day: 1, startTime: 0, endTime: 0, id: '' },
    { day: 2, startTime: 0, endTime: 0, id: '' },
    { day: 3, startTime: 0, endTime: 0, id: '' },
    { day: 4, startTime: 0, endTime: 0, id: '' },
    { day: 5, startTime: 0, endTime: 0, id: '' },
    { day: 6, startTime: 0, endTime: 0, id: '' },
    { day: 7, startTime: 0, endTime: 0, id: '' }
  ],
  assignedSkills: [],
  assignedPersonaIds: [],
  assignedLocationIds: []
};

export interface WorkingPatterns {
  startTime: number;
  endTime: number;
  day: number;
  id: string;
}

export interface LocationWithSettings {
  location: Location;
  settings: LocationSettings;
  details: LocationDetails;
}

export interface LocationDetails {
  companyCode: string;
  companyName: string;
  divisionName: string;
  brandName: string;
  perfecture: string;
  openingDate?: Date;
  closedDate?: Date;
}

export const defaultLocationDetails: LocationDetails = {
  companyCode: '',
  companyName: '',
  divisionName: '',
  brandName: '',
  perfecture: ''
};

export interface LocationSettings {
  supervisorId: string;
  announcements: Announcement[];
}

export const defaultLocationSettings: LocationSettings = {
  supervisorId: '',
  announcements: []
};
