import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    ess: {
      myProfile: {
        title: 'My Profile',
        email: 'Email',
        phone: 'Phone',
        notification: 'Notification',
        sms: 'SMS',
        editInfo: 'Edit Info'
      },
      announcements: {
        title: 'Announcements'
      },
      monthlyShift: {
        title: 'Monthly Shift',
        monthlySchedule: 'Monthly Schedule',
        noShifts: 'Monthly schedule is not ready yet',
        published: (date: string, time: string) => `Published ${date} (${time})`
      },
      vacantShifts: {
        title: 'Vacant Shifts'
      },
      availability: {
        title: 'Availability',
        totalEmployees: 'Total employees submitting holiday requests.',
        saveConfirm: 'You have unsaved changes to your availability. Are you sure you want to leave?',
        submitAvailability: 'Submit',
        mode: {
          name: {
            work: 'Work',
            available: 'Available',
            roster: 'RDO',
            paid: 'Paid',
            unavailable: 'Unavailable'
          },
          label: {
            roster: 'RDO',
            paid: 'Paid day off'
          },
          description: {
            cannotEdit: 'cannot edit',
            default: 'Default',
            taps: (count: number) => (count > 0 ? `${count} tap${count === 1 ? '' : 's'}` : '')
          }
        },
        warningModal: {
          titleWarnings: 'Warnings',
          titleErrors: 'Errors',
          exceededMandatory: (numberRequired: number, numberTaken: number, type: string) =>
            `You may not take more than ${numberRequired} ${type} days in this roster period. (Days taken: ${numberTaken})`,
          exceededWarning: (numberRequired: number, numberTaken: number, type: string) =>
            `You are above the recommended limit of ${numberRequired} ${type} days in this roster period. (Days taken: ${numberTaken})`,
          notMetMandatory: (numberRequired: number, numberTaken: number, type: string) =>
            `You are required to take ${numberRequired} ${type} days in this roster period. (Days taken: ${numberTaken})`,
          notMetWarning: (numberRequired: number, numberTaken: number, type: string) =>
            `You are below the recommended limit of ${numberRequired} ${type} days in this roster period. (Days taken: ${numberTaken})`,
          availabilityBelowMinDuration: (date: string, duration: number, minShiftDuration: number) => `
          Availability for ${date} is below the min shift duration: ${minShiftDuration} hours. (Duration: ${duration} hours)
          `,
          availabilityOutsidePattern: (date: string, patternStart: string, patternEnd: string) => `
          Availability for ${date} is outside of your usual working pattern. (Usual pattern: ${patternStart} - ${patternEnd})
          `
        }
      }
    },
    daily: {
      drag: {
        instructions: 'Drag & drop to change the chart:',
        shiftTypes: {
          work: 'Work',
          break: 'Break',
          business: 'Business'
        },
        shift: 'Shift',
        tasks: 'Tasks',
        showAll: 'Show all',
        showFavourites: 'Show favourites',
        expand: 'Expand',
        taskTooltips: {
          capableStaffCount: (count: number, date: string) => `${count} staff can do this task on: ${date}`,
          capableStaffCountTemplate: (count: number) =>
            `There are ${count} staff skilled to perform this task as this location`,
          mandatorySkills: 'Mandatory skills',
          optionalSkills: 'Optional skills',
          noMandatorySkills: 'No mandatory skills',
          noOptionalSkills: 'No optional skills',
          staffWhoCanPerform: 'Staff who can perform this task',
          noStaffCanPerformTask: 'No staff have all the mandatory skills to perform this task',
          availableToday: (time: string) => `Available today ${time}`,
          notAvailableToday: 'Not available today'
        }
      },
      roster: {
        noRoster: 'No roster has been created for this day. Would you like to create one from the template?',
        create: 'Create Roster',
        createInProgress: 'Creating a roster...',
        unsaved: 'Your roster has unsaved changes. Are you sure you want to leave?',
        noStaff: 'No staff available',
        budgetStats: {
          totalHq: 'Monthly Location Forecast',
          totalRostered: 'Monthly Rostered Budget',
          monthlyDelta: 'Monthly Delta Time',
          locationForecast: 'Location Forecast',
          rosteredBudget: 'Rostered Budget',
          deltaTime: 'Delta Time',
          totalHqAllocatedBudget: 'Total HQ Allocated Budget',
          monthlyLocationForecast: 'Monthly Location Forecast',
          monthlySales: 'Monthly sales',
          dailySales: 'Daily sales'
        },
        selectTemplate: 'Select template',
        changeTemplateWarning:
          'Changing the template will replace all roster data for this day. Would you like to change the template?',
        deleteReassignedShiftWarning:
          'Please arrange for the assigned staff member to be removed from the providing location.',
        templates: {
          label: 'Templates',
          saveAsNew: 'Save as new template',
          saveAsNewTitle: 'Do you want to save this pattern as new template?',
          replaceExisting: 'Replace existing template',
          replaceExistingTitle: 'Do you want to replace existing template?',
          replace: 'Replace',
          replaceTemplate: 'Replace Template',
          chooseTemplate: 'Choose your template destination',
          replaceWarning: (name: string) =>
            `Template with name "${name}" already exists. Would you like to replace the existing template?`,
          maxTemplatesLimitReached: (limit: number) =>
            `The limit of "${limit}" allowed custom templates has been already reached! You can replace your current templates instead.`,
          tooltip: {
            templateName: (name: string) => `Template imported as ${name}`,
            templateVersion: (version: string) => `Version ${version}`
          }
        },
        publish: 'Publish Roster',
        published: 'Published',
        lastSaved: 'Last Saved',
        lastPublished: 'Last Published',
        notifyVacant: 'Announce Vacant Shifts',
        deleteModal: {
          title: 'Delete roster',
          message: 'Are you sure you want to delete this roster?'
        },
        saveWorkInProgressModal: {
          title: 'Save work in progress',
          message: 'You are saving a shift does not have any time allocated, do you want to continue?'
        },
        tooltip: {
          noneAssigned: 'No staff member has been assigned to this shift.',
          probationary: (date: string) => `On probation until ${date}`,
          foreignStudent: 'Foreign student',
          canMentor: 'Can be a mentor',
          requiresMentor: 'Must have a mentor',
          notAdult: 'Under 18 years of age',
          ageNotSpecified: 'Employees age is not recorded',
          leaving: (date: string) => `Leaving on ${date}`,
          left: (date: string) => `Left on ${date}`,
          management: '(Management Level)',
          templateName: (name: string) => `Roster created from ${name}`,
          templateVersion: (version: string) => `Version ${version}`,
          unableToGetTemplate: 'Template data is not available',
          lastUpdated: 'Last edit:'
        },
        noRosterCreated: 'No roster was created for this day.',
        print: {
          title: (date: string, location: string) => `${location}: Daily roster for ${date}`,
          fileName: (date: string, location: string) => `roster-${location}-${date}.pdf`
        },
        errors: {
          employeeAlreadyAssigned: (employeeName: string, employeeId?: string) =>
            `${employeeName} (${employeeId ?? ''}) is already assigned to another location`
        },
        noNotes: 'No notes for this roster day.',
        rosterHistory: 'Roster History',
        shiftLockedError: `This shift is locked. Please unlock it before changing to a different employee.`
      },
      templates: {
        noTemplates: 'No templates have been defined for this location.',
        creatingTemplate: 'Setting up the template...',
        selectRole: 'Select role',
        unsaved: 'Your template has unsaved changes. Are you sure you want to leave?',
        createReplaceUnsaved:
          'Not all your changes have been saved. Are you sure you want to copy the previously saved state?',
        clearTemplate: 'Clear Template',
        contractTypes: {
          shopManager: 'Shop Manager',
          deputyShopManager: 'Deputy Shop Manager',
          fullTime: 'Full Time',
          partTime: 'Partner',
          reducedHours: 'Reduced Hours',
          zeroHours: 'Sales Staff',
          casual: 'Casual',
          student: 'Student'
        },
        myTemplates: {
          label: 'My Templates',
          createNewTemplate: 'New template',
          noTemplates: 'No templates created yet.',
          nameRequired: 'Template name is required.',
          nameExists: 'Template name already exists.',
          addModal: {
            title: 'Create a new template',
            enterTemplateName: 'Enter template name',
            generalError: 'Unable to create template'
          },
          editModal: {
            title: 'Edit custom template',
            editTemplateName: 'Edit template name',
            generalError: 'Unable to edit template'
          },
          deleteModal: {
            title: 'Delete custom template',
            deleteConfirmation: (name: string) =>
              `Click the Delete button to remove the '${name}' template from the templates list.`,
            generalError: 'Unable to delete template',
            inUseError: 'Template is in use- unable to delete'
          },
          limitModal: {
            title: 'Template Limit Reached',
            info: (max: number) =>
              `You have reached the limit of ${max} custom template${
                max === 1 ? '' : 's'
              }. Please delete an existing template before adding a new one.`
          }
        },
        shopTemplates: 'Shop Templates',
        libraryTemplates: 'Library Templates',
        library: {
          notPermitted: 'Only a template librarian may view this page.',
          noneCreated: "You haven't created any library templates yet.",
          create: 'Create New Library Template',
          importModal: {
            title: 'Import Library Template',
            selectTemplate: 'Please select a template to import.',
            pleaseSelect: 'Please select',
            alreadyImported: 'That template has already been imported.',
            mustSelect: 'You must select a template.',
            noTemplates: 'No library templates have been defined',
            noSearchResults: 'There are no templates matching your search.',
            placeholder: 'Search for a template by name or by tag'
          }
        },
        noNotes: 'No notes for this template.',
        templateHistory: 'Template History',
        importNotes: (dateString: string, libraryName: string) =>
          `Template imported as ${libraryName} on ${dateString}.` as string,
        version: 'Version',
        tags: {
          label: 'Tags',
          description: 'You can add tags to your template to make them easier to search.',
          placeholder: 'Enter a name for your tag',
          otherTags: 'Other Tags:',
          andMore: (count: number) => `+${count} more`,
          none: 'No tags defined'
        }
      },
      summary: {
        scratchTotal: `Scratch Pad Total`,
        totalActualEmployee: `Total actual employee`,
        totalTime: 'Total Time'
      },
      employeesDropdown: {
        column: {
          status: 'Status',
          name: 'Name',
          timeAvailable: 'Time available',
          allocation: 'Allocation',
          role: 'Role',
          skills: 'Skills',
          tasks: 'Tasks',
          taskNames: 'Capable tasks',
          holidays: 'Total Monthly Holidays',
          attendances: 'Total Monthly Attendances',
          workingHours: 'Total Monthly Working Hours'
        },
        employeeStatus: {
          available: 'Available',
          partly_available: 'Partly available',
          unavailable: 'Unavailable'
        },
        Allocation: {
          allocated: 'Allocated',
          notAllocated: 'Not allocated'
        },
        availabilities: {
          header: 'Available staff from other locations',
          footer: 'After staff coverage has been arranged, press Action to assign staff into your roster',
          action: 'Action',
          notFound: 'No availabilities from other locations found!'
        },
        select: 'Select',
        myLocation: 'My location',
        fromOtherLocations: 'Available Staff From Other Locations',
        timeRequired: 'Time required',
        waitingAllocation: 'Waiting on allocation',
        noStaffAvailable: 'No staff are available to work this shift.',
        noStaffAvailableFromOtherLocations: 'No staff from other locations are available to work this shift.'
      },
      staffAllocation: {
        sendStaffToAnotherLocation: 'Send staff to another location',
        selectShiftsAndLocations: 'Select shifts and locations',
        noNeedOfAllocation: 'No locations have registered they are looking for staff',
        staffRequest: 'Staff request'
      },
      changeTimes: {
        startTime: 'Start Time',
        endTime: 'End Time',
        startError: (time: string) => `Cannot change start time: there is a shift starting at ${time}`,
        endError: (time: string) => `Cannot change end time: there is a shift ending at ${time}`,
        openBeforeStartError: 'Opening time cannot be before start time',
        closeAfterEndError: 'Closing time cannot be after end time',
        closeBeforeOpenError: 'Closing time must be after open time',
        lengthError: (hours: number) => `The day must cover at least ${hours} hours.`
      },
      changeOpenClosingTimes: {
        openTime: 'Opening Time',
        closeTime: 'Closing Time'
      },
      taskCapability: {
        available: `Staff are available with the required skills`,
        partlyAvailable: `Staff are only partly available with the required skills`,
        unavailable: `No staff are available with the required skills`
      }
    },
    budget: {
      rows: {
        financial: 'Sales target (¥)',
        hqAllocated: 'HQ Allocated Budget',
        locationForecast: 'Location Forecast',
        rosteredBudget: 'Rostered Budget',
        deltaTime: 'Delta Time',
        needStaff: 'Need Staff',
        totalEmployees: 'Employees Rostered/Required'
      },
      vacantShifts: {
        title: (count: number) => `Vacant Shift${count === 1 ? '' : 's'} (${count.toString()})`
      },
      noTargets: 'Target data has not been imported for this date.',
      unsavedChanges: 'There are unsaved changes to the budget. Are you sure you want to leave?',
      filePrefix: 'budget',
      uploadBudget: 'Upload Budget',
      csvHeader: {
        shopId: 'Shop Id',
        shopName: 'Shop Name',
        date: 'Date',
        locationForecast: 'Location Forecast',
        dailySalesTarget: 'Daily Sales Target',
        rosterHours: 'Roster Hours'
      }
    },
    summary: {
      monthlyTotal: 'Monthly Total',
      max: 'Max.',
      min: 'Min.',
      periodTotal: 'Period Total',
      monthly: 'Monthly',
      weekly: 'Weekly',
      holiday: 'H',
      available: 'A',
      reassigned: 'OS',
      noData: 'Employee data has not been setup for the given period.',
      generate: 'Generate Rosters',
      allocateStaff: 'Allocate Staff',
      allocateStaffMessage: 'Please make sure all shop managers have already generated the rost, this process will take awhile.',
      askForConfirmation: 'Do you wish to proceed?',
      total: 'Total',
      employee: 'Employee',
      publish: 'Publish Rosters',
      published: 'Published',
      lastPublished: 'Last Published',
      shiftSummaryFileName: 'rosters',
      taskSummaryFileName: 'tasks',
      notificationTitle: 'Announcement of shift release',
      notificationMessage: 'Shifts has been published. Please check the published shifts.',
      states: {
        paid: 'Paid day off',
        dayOff: 'Day off',
        rosterDayOff: 'RDO',
        working: 'Working'
      },
      print: {
        weekly: {
          fileName: (date: string) => `weekly-summary-${date}.pdf`,
          fileTitle: (date: string) => `Weekly Summary: ${date}`
        },
        monthly: {
          fileName: (date: string) => `monthly-summary-${date}.pdf`,
          fileTitle: (date: string) => `Monthly Summary: ${date}`
        }
      },
      publishNotification: {
        prefix: 'Rosters for',
        success: 'have been published.',
        failure: 'could not be published.'
      },
      resetRosters: {
        buttonLabel: 'Reset Rosters',
        modal: {
          title: 'Reset rosters',
          message: 'Do you want to reset all the rosters in the current roster period?'
        },
        error: 'Failed to reset rosters'
      },
      generateConfirmation: (period: string) =>
        `This action will delete all existing rosters for ${period}. Do you want to continue?`,
      compliance: {
        totalHolidays: 'Total Monthly Holidays',
        totalAttendance: 'Total Monthly Attendance Day',
        totalShifts: 'Total Monthly Shifts',
        totalUnassigned: 'Total Monthly Unassigned Avaliable Days',
        showCompliance: 'Show Compliance',
        showMonthly: 'Show Monthly',
        totalWorkDays: 'Total Work Days',
        totalRosterShifts: 'Total Shifts',
        totalRosterUnassigned: 'Total Unassigned Shifts',
        advancedLeaveHeader: 'Leave / Unavailability Totals',
        advancedLeaveTotal: 'Overall Total',
        rostered: 'Rostered',
        notRostered: 'Not Rostered'
      },
      monthlySort: {
        sort: 'Sort',
        item: 'Item',
        ascDes: 'Ascending/Descending',
        max: 'Max Working Hour (Monthly)',
        min: 'Min Working Hour (Monthly)',
        periodTotal: 'Period Total (Monthly)',
        shopPosition: 'Shop position',
        name: 'Name',
        employeeId: 'Employee Id'
      },
      monthlyTotalTooltip: {
        totals: 'Totals:',
        employeeRosteredRequiredHeader: 'Employees rostered/required',
        employeeRosteredRequired:
          'are totals for this location, including staff from other locations that are temporarily working at this location.',
        monthlyTotalsHeader: 'Monthly Totals',
        monthlyTotals:
          'are totals for all staff who are permanently assigned to this (their home) location, including if they are temporarily assigned to work at another location.'
      }
    },
    settings: {
      unsaved: 'There are unsaved changes. Are you sure you want to leave?',
      pages: {
        system: 'System',
        manageStaff: 'Manage Staff',
        manageSkills: 'Manage Staff Skills',
        availability: {
          name: 'Manage Availability',
          print: {
            fileName: (date: string) => `manage-availability-${date}.pdf`,
            fileTitle: (date: string) => `Manage Availability: ${date}`
          }
        },
        locations: {
          label: 'Manage Locations',
          essMessages: 'ESS Announcements',
          myLocation: 'My Location'
        },
        skills: 'Manage Skills',
        tasks: 'Manage Tasks',
        personas: 'Manage Personas',
        advancedLeave: 'Manage Advanced Leave',
        standardLeave: 'Manage Standard Leave',
        userAccounts: 'Manage User Accounts',
        organisationChart: 'Organisation Chart'
      },
      manageAvailability: {
        availability: 'Availability',
        hours: 'Available Hours',
        rosteredHours: 'Rostered Hours',
        rosteredLocation: 'Rostered Location',
        rosteredLocationNotRostered: 'Not Rostered',
        rosteredLocationHome: (locationId: string, locationName: string) =>
          `Home location ${locationId} ${locationName}`,
        rosteredLocationRemote: (locationId: string, locationName: string) =>
          `Remote location ${locationId} ${locationName}`,
        pattern: 'Work Pattern Hours',
        endBeforeStart: 'The end time must be before the start time.',
        leaveForPeriod: 'Leave for this roster period',
        inactiveLeaveToolTip: (leaveName: string) => `${leaveName} is now a disabled leave type`,
        warnings: {
          criticalWarning: 'Critical Warning',
          optionalWarning: 'Optional Warning',
          noAvailability: 'The user has been allocated a shift outside of their availability.',
          partialAvailability: 'The user is only partially available for this shift.'
        }
      },
      manageStaff: {
        employeeId: 'Employee ID',
        employeeName: 'Employee Name',
        katakanaName: 'Katakana Name',
        authority: 'Authority/Persona',
        selectPersonas: 'Select authority',
        selectLocations: 'Select locations',
        workPattern: {
          headerLabel: 'Work Pattern'
        },
        skills: {
          headerLabel: 'Skills'
        },
        mixMax: {
          monthlyHeaderLabel: 'Monthly',
          weeklyHeaderLabel: 'Weekly',
          minHours: 'Min Hours',
          maxHours: 'Max Hours',
          weeklyExceedsMonthly: 'Maximum weekly hours exceeds maximum monthly hours'
        },
        personnelDetails: {
          headerLabel: 'Personnel Details',
          dateOfBirth: 'Date of birth',
          hiredDate: 'Hired Date',
          endProbation: 'End of Probation Date',
          mentor: 'Mentor',
          requiresMentor: 'Requires Mentor',
          leftCompanyDate: 'Left Company Date',
          leftCompanyWarning: (name: string, date: string) => `${name} has shifts on the following days after ${date}:`
        },
        location: {
          headerLabel: 'Location Details',
          assignLocation: 'Assigned Location',
          shopPosition: 'Shop Position',
          aditionalLocations: 'Additional locations'
        },
        role: {
          admin: 'Admin',
          seniorManager: 'Senior Manager',
          manager: 'Manager',
          staff: 'Staff'
        },
        resetPassword: 'Reset Password',
        resetButton: 'Reset',
        deleteUser: 'Delete',
        deleteButton: 'Delete',
        deleteCancel: 'Cancel',
        casual: 'Casual',
        addCasual: {
          firstName: 'First Name',
          lastName: 'Last Name',
          delete: 'Delete',
          add: 'Add Casual Staff'
        },
        noNotes: 'No notes for this employee.'
      },
      manageAnnouncements: {
        announcement: 'Announcement',
        activeInactive: 'Active / Inactive',
        title: 'Title',
        text: 'Text',
        displayDate: 'Display date',
        showAtDate: 'Show at date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        emptyAnnouncementsList: 'The list of announcements is empty!',
        unsavedChanges: 'There are unsaved changes to the announcements. Are you sure you want to leave?'
      },
      location: {
        locationNumber: 'Location Number',
        locationName: 'Location Name',
        supervisor: 'Supervisor',
        companyCode: 'Company Code',
        companyName: 'Company Name',
        divisionName: 'Division Name',
        brandName: 'Brand Name',
        SVName: 'SV Name',
        prefecture: 'Prefecture',
        shopName: 'Shop Name',
        shopCode: 'Shop Code',
        openingDate: 'Opening Date',
        closedDate: 'Closed Date',
        inventoryDate: 'Inventory Date',
        shiftRequestDeadline: 'Shift Request Deadline',
        deadlineESSReminder: 'Deadline ESS Reminder',
        maxLeaveEachDay: 'Max. Leave Each Day (Person)',
        shopType: 'Shop Type',
        notDefined: 'Not defined'
      },
      manageSkills: {
        skill: 'Skill',
        activeInactive: 'Active / Inactive',
        skillName: 'Skill name',
        tooltip: 'Tooltip',
        emptySkillsList: 'The list of skills is empty!',
        print: {
          staffSkillsOverview: 'Staff Skills Overview',
          skillCapability: 'Skills Capability',
          printBySkill: 'Print skills capabilities',
          printByStaffName: 'Print staff skill overview'
        },
        errors: {
          inUse: 'Skills are in use and cannot be made inactive',
          optional: (skill: string, task: string) => `Skill ${skill} is an optional skill for task: ${task}`,
          mandatory: (skill: string, task: string) => `Skill ${skill} is a mandatory skill for task: ${task}`
        },
        export: {
          success: 'Staff Skill CSV successfully exported'
        },
        import: {
          success: (count: number) => `${count} staff have had their skills updated.`
        }
      },
      manageTasks: {
        task: 'Task',
        activeInactive: 'Active / Inactive',
        taskName: 'Task name',
        displayName: 'Display name',
        icon: 'Icon',
        textColor: 'Text color',
        colors: 'Colors',
        defaultDuration: 'Default duration (minutes)',
        wholeShift: 'Fit to shift',
        supplementaryTask: 'Supplementary task',
        includeInSummaryTotals: 'Include in monthly summary totals',
        calculateWorkingHours: 'Calculate working hours',
        skill: 'Mandatory Skills',
        optionalSkill: 'Optional Skill',
        selectSkills: 'Select skills',
        emptyTasksList: 'The list of tasks is empty!',
        activeTasks: 'Active Tasks',
        inactiveTasks: 'Inactive Tasks',
        yes: 'Y',
        no: 'N',
        printFileName: (organisationName: string, date: string) => `${organisationName}-tasks-${date}.pdf`,
        errors: {
          inUse: 'Tasks are in use and cannot be made inactive',
          shopTemplate: (task: string) => `Task ${task} is currently allocated to the following shop templates:`,
          libraryTemplate: (task: string) => `Task ${task} is currently allocated to the following library templates:`
        }
      },
      managePersonas: {
        persona: 'Persona',
        personaName: 'Persona name',
        admin: {
          name: 'Admin Only',
          tooltip: `Only admins can set/remove this persona.`
        },
        management: {
          name: 'Management',
          tooltip: `Whether a user can act as a manager. Relates to the setting "Management Attendance Required".`
        },
        templateLibrarian: {
          name: 'Template Librarian',
          tooltip: `Whether a user can make updates to the template library.`
        },
        emptyList: 'The list of personas is empty!'
      },
      manageLeave: {
        active: 'Active / Inactive',
        leaveUnavailabilityType: 'Leave / Unavailability Type',
        displayName: 'Display Name',
        systemCode: 'External System Code',
        colors: 'Colors',
        measurementUnits: 'Measurement Units',
        units: {
          days: 'Days',
          hours: 'Hours'
        },
        maximumTotal: 'Maximum Total',
        minimumTotal: 'Minimum Total',
        level: 'Warning/Mandatory',
        maximumConsecutive: 'Maximum Consecutive',
        canOverride: 'Management can override',
        emptyList: 'The list of leave types is empty!',
        leaveType: 'Leave Type',
        per: 'per',
        period: {
          rosterPeriod: 'Roster Period',
          year: 'Year',
          month: 'Month',
          day: 'Day',
          week: 'Week'
        },
        warning: 'Warning',
        mandatory: 'Mandatory',
        override: 'Management can override',
        leaveUnavailabilityTypeToolTip: 'This name is used in export data if required',
        displayNameToolTip:
          'This is the name displayed in the UI, including ESS. It will be truncated to the first few characters if there is not enough room to display in the UI',
        systemCodeTooltip: 'Codes for integration to external HR systems, not used in SNPS Shift',
        maximumTotalTooltip:
          'Disabled = warning when max is exceeded, can submit the total. Enabled = ESS user cannot exceed the max total, cannot submit the total',
        maximumConsecutiveToolTip: 'Currently hard coded value, not currently enforced',
        availableInEss: 'Available in ESS?',
        alwaysShown: 'N/A Always Shown'
      },
      system: {
        desktopAppBehaviour: {
          label: 'Desktop App Behaviour',
          title: 'Highlight system tabs',
          description: 'Set the appearance and colour'
        },
        customerSpecific: {
          label: 'Customer Specific',
          allocateStaff: 'Enable Allocate Staff Button',
          allocateStaffDescription: 'This setting is for SUNDI',
        },
        orgName: {
          title: 'Display Company/Organisation',
          description: 'Define what is displayed in the top right',
          options: {
            company: 'Company',
            division: 'Division',
            brand: 'Brand',
            custom: 'Custom name'
          },
          default: 'Organisation Name'
        },
        roster: {
          title: 'Roster Data',
          settings: {
            rosterMonthStart: {
              title: 'Roster month start day',
              description: 'Define the starting day of the month'
            },
            rosterWeekStart: {
              title: 'Week view start day',
              description: 'Define the starting day of the week'
            },
            defaultStartEnd: {
              title: 'Default template period',
              description: 'Default start and end time used when a template is created',
              startTime: 'Start time until',
              endTime: 'End time'
            },
            futureAvailabilityMonths: {
              title: 'Maximum future availability',
              description: 'How many months in advance the system shows the calendar',
              monthCount: (count: number) => `${count} month${count === 1 ? '' : 's'}`
            },
            includeBreaks: {
              title: 'Include breaks in roster totals',
              description: 'Whether time spent on break should be included in the roster totals'
            },
            breakOffset: {
              title: 'Break offset',
              description: 'Earliset/latest breaks can be set for the beginning/end of the day',
              firstBreak: 'Set how many minutes after the start of the shift the first break can occur',
              lastBreak: 'set how many minutes before the end of the shift the last break can occur'
            },
            holidayRegion: {
              title: 'Public holiday region',
              description: 'Which region to show public holidays for',
              options: {
                none: 'None',
                japan: 'Japan'
              }
            },
            minShiftDuration: {
              title: 'Minimum shift duration',
              description: 'Minimum time for a shift. A shift cannot be made shorter than this value',
              minShiftCannotExceedDefaultDuration: 'Minimum shift time cannot be larger than the default duration'
            },
            defaultShiftDuration: {
              title: 'Default shift duration',
              description: 'Default time for a new shift in templates and daily roster'
            },
            maxTimePeriods: {
              title: 'Maximum number of available time periods in each roster period',
              description:
                'The number of time periods an employee can be specified as available (ESS and Manage Staff Availability)',
              count: (number: number) => `${number} shift${number > 1 ? 's' : ''}`
            },
            availabilityDeadline: {
              title: 'Availability Deadline',
              description: 'Which day of the month staff members must input their availability by',
              toggle: 'Automatically Send Reminders',
              notificationDate: 'Notification Date',
              notificationTime: 'Notification Time'
            },
            validation: {
              title: 'Roster Editing Validation',
              managementAttendance: {
                title: 'Management Attendance Required',
                description:
                  'A Manager or Deputy Manager must be in attendance each day (for the duration of a minimum shift)'
              },
              mandatorySkills: {
                title: 'Enforce Mandatory Skills',
                description: 'Mandatory skills requirements are ignored'
              }
            }
          }
        },
        personnelDetails: {
          title: 'Edit Personnel details',
          description: 'Enable personnel date to be editable rather than read only',
          editable: {
            dateOfBirth: 'Date of birth',
            hiredDate: 'Hired Date',
            leftDate: 'Left Company Date',
            endProbDate: 'End of probation date'
          },
          level: {
            view: 'View',
            hide: 'Hide',
            edit: 'Edit'
          }
        },
        lastOfMonth: 'Last',
        optional: 'Optional'
      },
      admin: {
        title: 'Super Admin Settings - For SNPS use only',
        useWithCaution: 'Use with caution',
        settings: {
          minimumIncrement: {
            title: 'Minimum shift increment',
            description: 'Smallest number of minutes a shift start or end time can be changed',
            minutes: (number: number) => `${number} minutes`
          },
          canDeleteRosters: {
            title: 'Can Delete Rosters',
            description: 'Whether daily rosters can be deleted'
          },
          displayEmployeeIds: {
            title: 'Display Employee Ids',
            description: 'Display employee IDs alongside names'
          },
          minStaffHours: {
            title: 'Minimum staff hours',
            description: 'Default least number of hours a staff member has to work each month'
          },
          maxStaffHours: {
            title: 'Maximum staff hours',
            description: 'Default highest number of hours a staff member can work each month'
          },
          maxCustomTemplates: {
            title: 'Custom template maximum',
            description: 'The number of custom templates a customer can create'
          },
          splitShifts: {
            sectionName: 'Split Shifts',
            maxNumber: {
              title: 'Maximum number of shifts',
              description:
                '1 through 3 shifts per employee in any given roster period. 1 shift implies split shifts are disabled.',
              count: (number: number) => `${number} shift${number > 1 ? 's' : ''}`
            },
            minTimeBetween: {
              title: 'Minimum time allowed between shifts',
              description: 'Less than this amount implies a shift with a break'
            }
          },
          reassignment: {
            sectionName: 'Reassignment to other locations',
            model: {
              title: 'Reassignment model',
              onRequestModel: 'On-request model',
              pullModel: 'Pull model',
              disabled: 'Disable reassignment'
            },
            locations: {
              title: 'Staff work at all locations or allocated locations',
              description:
                'Allocated = Locations allocated in Manage Staff or ESS (can be anywhere in the organisation) / All locations = All locations under the SV (assumption all staff are willing to work at locations managed by the SV)',
              allLocations: 'All Locations',
              allocatedLocations: 'Allocated'
            }
          }
        },
        dataCleanUp: {
          title: 'Data clean up',
          deleteAllRosters: {
            title: 'Delete ALL rosters',
            confirmationHeader: 'Delete all ROSTERS',
            confirmationMessage:
              'You are going to delete all rosters data. The operation could not be undone! Do you still want to proceed?'
          },
          deleteAllTemplates: {
            title: 'Delete ALL templates',
            subtitle: 'Note: delete rosters before deleting templates!',
            confirmationHeader: 'Delete all TEMPLATES',
            confirmationMessage:
              'You are going to delete all templates data. The operation could not be undone! Do you still want to proceed?'
          },
          deleteAllAvailability: {
            title: 'Delete ALL availability',
            confirmationHeader: 'Delete all AVAILABILITY',
            confirmationMessage:
              'You are going to delete all availability data. The operation could not be undone! Do you still want to proceed?'
          }
        },
        dataExport: {
          title: 'Data Export',
          tenantData: {
            title: 'Export Tenant Data',
            subtitle: 'Export to SNPS Shift generic format'
          }
        },
        userMaintenance: {
          title: 'User maintenance',
          resetAllPasswords: {
            title: 'Reset ALL passwords to default',
            confirmationHeader: 'Reset all users PASSWORDS',
            confirmationMessage:
              'You are going to reset all user accounts passwords to their default state. The operation could not be undone! Do you still want to proceed?'
          }
        },
        leaveManagement: {
          title: 'Leave/Availability',
          advanced: {
            title: 'Standard or Advanced',
            description: 'Standard has unavailable only',
            labelOn: 'Advanced',
            labelOff: 'Standard'
          }
        },
        optimizer: {
          title: 'Optimizer Parameters',
          runtimeDuration: {
            title: 'Optimizer runtime duration',
            description:
              "Number of seconds the optimizer will run (assembling/disassembling the payload isn't included in the timeframe!)"
          },
          management: {
            title: 'Management persona in attendance',
            description: 'Hard constraint to force attendance for at least one shift per roster'
          }
        },
        organisationChart: {
          orphanedLocationsHolderName: 'Orphaned Locations',
          filters: {
            displayCurrent: 'Display Current Employees',
            displayAll: 'Display All Employees',
            displayPast: 'Display Past Employees'
          }
        }
      },
      filter: {
        displayActive: 'Display active',
        displayAll: 'Display all',
        displayInactive: 'Display inactive'
      },
      staffFilter: {
        displayCurrent: 'Display Current Employees',
        displayAll: 'Display All Employees',
        displayPast: 'Display Past Employees',
        errors: {
          dataError: 'Data Error: please correct the following',
          invalidMonthlyTimes: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}, Invalid times in Monthly Min/Max Hours`,
          invalidWeeklyTimes: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}, Invalid times in Weekly Min/Max Hours`,
          invalidWorkPatternTimes: (employeeId: string, employeeName: string, day: string) =>
            `${employeeId} ${employeeName}, Invalid times in Work Pattern ${day}`,
          invalidLeavingDate: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}, Invalid left company date`
        }
      },
      preferences: {
        dailyRoster: {
          sectionTitle: 'Daily Roster',
          staffPicker: 'Staff Picker Columns',
          taskDisplay: 'Task Display',
          status: 'Status',
          timeAvailable: 'Time Available',
          allocation: 'Allocation',
          role: 'Role',
          totalHolidays: 'Total Monthly Holidays',
          attendances: 'Total Monthly Attendances',
          workingHours: 'Total Monthly Working Hours',
          tasks: 'Tasks',
          capableTasks: 'Capable Tasks',
          personalization: 'Personalization',
          ganttGridLines: 'Gantt Grid Lines',
          ganttGridBarThickness: 'Gantt Grab Bar Thickness',
          openClosingTimes: {
            label: 'Open/Closing Times',
            hidden: 'Hidden',
            fieldsOnly: 'Fields only',
            all: 'Fields and Gantt Bar'
          },
          gridLines: (value: number) => `${value} mins`
        }
      }
    },
    optimiser: {
      notification: {
        generated: 'has been generated.',
        progress: 'is in progress.',
        queued: 'has been queued.',
        deleted: 'has been deleted.',
        failed: (error: string) => `has failed with error code ${error}.`,
        rosterFor: 'Roster for'
      },
      inProgress: 'Optimisation in progress...'
    },
    layout: {
      headerLinks: {
        monthlyBudgets: 'Monthly Budgets',
        monthlyShifts: 'Monthly Shifts',
        weeklyShifts: 'Weekly Shifts',
        dailyShifts: 'Daily Shifts',
        templates: 'Templates',
        systemSetup: 'System Setup',
        notifications: 'Notifications',
        location: 'Location'
      },
      headerMenu: {
        myProfile: 'My Settings',
        language: 'Language',
        languages: {
          english: 'English',
          japanese: 'Japanese'
        },
        logout: 'Logout'
      },
      underConstruction: 'This page is still in development.'
    },
    common: {
      sort: 'Sort',
      print: 'Print',
      printModal: {
        title: 'Print Options',
        screenView: 'Screen View',
        posterView: 'Poster View'
      },
      upload: 'Upload',
      download: 'Download',
      na: 'N/A',
      time: 'Time',
      date: 'Date',
      submit: 'Submit',
      cancel: 'Cancel',
      create: 'Create',
      save: 'Save',
      publish: 'Publish',
      edit: 'Edit',
      delete: 'Delete',
      deleteAll: 'Delete all',
      reset: 'Reset',
      resetAll: 'Reset all',
      toBeDeleted: 'To be deleted!',
      close: 'Close',
      view: 'View',
      loading: 'LOADING...',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phone: 'Phone',
      phoneNumber: 'Phone number',
      pagination: {
        pageXOfY: (page: number, total: number) => `Page ${page} of ${total}`
      },
      search: 'Search',
      mandatory: (field: string) => `${field} is required`,
      ok: 'OK',
      continue: 'Continue',
      yes: 'Yes',
      no: 'No',
      locationName: 'Location Name',
      total: 'Total',
      employee: 'Employee',
      daysOfWeek: ['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'],
      daysOfWeekMid: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      daysOfWeekFull: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Holiday'],
      daysOfWeekFullStartMonday: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
        'Holiday'
      ],
      success: 'Success!',
      error: 'An error occurred',
      select: 'Select',
      jp: 'JP',
      en: 'EN',
      today: 'Today',
      notPermitted: 'This account does not have permission to access this site.',
      file: {
        download: 'Download',
        upload: 'Upload',
        selectFile: 'Select file',
        pleaseSelect: 'Please select a file to upload.',
        downloadShifts: 'Download shifts',
        downloadSummaries: {
          title: 'Download Summaries',
          options: {
            shifts: 'Shifts',
            tasks: 'Tasks'
          }
        }
      },
      totals: {
        max: 'Max.',
        min: 'Min.',
        periodTotal: 'Period Total',
        dailyTotal: 'Daily Total',
        actualTotal: 'Actual Total',
        break: 'Break',
        monthlyTotal: 'Monthly Total',
        weeklyTotal: 'Weekly Total',
        attendanceTotal: 'Attendance Total'
      },
      enterValidPhone: 'Please enter a valid phone number.',
      enterValidEmail: 'Please enter a valid email.',
      severity: {
        critical: 'Critical warning',
        warning: 'Warning'
      },
      notesModalTitle: 'Notes',
      notesPrintLabel: 'Notes:',
      export: 'Export',
      import: 'Import',
      set: 'Set',
      add: 'Add'
    },
    notifications: {
      filter: {
        all: 'All',
        roster_generation: 'Roster generation',
        roster_publish: 'Roster publish',
        application: 'Application'
      },
      noNotifications: 'There are currently no messages to display for this session',
      push: {
        rosterPublishedTitle: 'Roster Published',
        rosterPublishedMessage: (startDate: string, endDate: string) =>
          `Roster in period ${startDate} - ${endDate} has been published.`,
        availabilityDeadlineTitle: 'Availability Deadline',
        availabilityDeadlineMessage:
          'Please ensure your availability for the coming roster period has been entered before close of business tomorrow.'
      },
      leftCompany: (name: string, date: string) =>
        `${name} left the company on ${date}, and is rostered on dates after their leaving date:`,
      willLeaveCompany: (name: string, date: string) =>
        `${name} will leave on ${date}, and is rostered on dates after their leaving date:`
    },
    keys: {
      reassigned: 'Reassigned',
      unassigned: 'Unassigned'
    },
    colors: {
      black: 'Black',
      white: 'White'
    },
    dropdown: {
      noOptions: 'No options to select!'
    }
  },
  ja: {
    ess: {
      myProfile: {
        title: '私のプロフィール',
        email: '郵便',
        phone: '電話',
        notification: '通知',
        sms: 'SMS',
        editInfo: '情報を編集する'
      },
      announcements: {
        title: 'お知らせ'
      },
      monthlyShift: {
        title: '月別シフト',
        monthlySchedule: '月別シフト',
        noShifts: 'シフトはまだ公開されていません',
        published: (date: string, time: string) => `最終シフト更新日 ${date} (${time})`
      },
      vacantShifts: {
        title: '空きシフト'
      },
      availability: {
        title: 'シフト希望入力',
        totalEmployees: '休みを提出している従業員数',
        saveConfirm: '保存されていない変更があります。変更を破棄してよろしいですか?',
        submitAvailability: '提出',
        mode: {
          name: {
            work: '出勤可能',
            available: '出勤可能',
            roster: '固定休',
            paid: '有休',
            unavailable: '出勤不可'
          },
          label: {
            roster: '固定休',
            paid: '有休'
          },
          description: {
            cannotEdit: '編集できません',
            default: 'デフォルト',
            taps: (count: number) => (count > 0 ? `${count}タップ` : '')
          }
        },
        warningModal: {
          titleWarnings: '警告',
          titleErrors: 'エラー',
          exceededMandatory: (numberRequired: number, numberTaken: number, type: string) =>
            `雇い入れ条件の契約日数を確保できない可能性がありますが、宜しいでしょうか？(${type}: 現在入力している日数${numberTaken}日/上限日数${numberRequired}日)`,
          exceededWarning: (numberRequired: number, numberTaken: number, type: string) =>
            `雇い入れ条件の契約日数を確保できない可能性がありますが、宜しいでしょうか？(${type}: 現在入力している日数${numberTaken}日/上限日数${numberRequired}日)`,
          notMetMandatory: (numberRequired: number, numberTaken: number, type: string) =>
            `雇い入れ条件の契約日数を確保できない可能性がありますが、宜しいでしょうか？(${type}: 現在入力している日数${numberTaken}日/上限日数${numberRequired}日)`,
          notMetWarning: (numberRequired: number, numberTaken: number, type: string) =>
            `雇い入れ条件の契約日数を確保できない可能性がありますが、宜しいでしょうか？(${type}: 現在入力している日数${numberTaken}日/上限日数${numberRequired}日)`,
          availabilityBelowMinDuration: (date: string, duration: number, minShiftDuration: number) => `
          ${date} の空き時間は、最小シフト期間 ${minShiftDuration} 時間を下回ります。 (期間: ${duration} 時間)
          `,
          availabilityOutsidePattern: (date: string, patternStart: string, patternEnd: string) => `
          ${date} の空き状況は、通常の作業パターンから外れています。 (通常のパターン: ${patternStart} ～ ${patternEnd})
          `
        }
      }
    },
    daily: {
      drag: {
        instructions: '画面にドラッグしてください:',
        shiftTypes: {
          work: '勤務',
          break: '休憩',
          business: '業務'
        },
        shift: 'シフト',
        tasks: 'タスク',
        showAll: 'すべて表示する',
        showFavourites: 'お気に入りを表示',
        expand: '拡大する',
        taskTooltips: {
          capableStaffCount: (count: number, date: string) =>
            `${count} 名のスタッフが ${date} にこのタスクを実行できます`,
          capableStaffCountTemplate: (count: number) => ` ${count} 名のスタッフがこのタスクを実行できます`,
          mandatorySkills: '必要なスキル',
          optionalSkills: 'オプションスキル',
          noMandatorySkills: '必要なスキルはありません',
          noOptionalSkills: 'オプションスキルなし',
          staffWhoCanPerform: 'このタスクを実行できるスタッフ',
          noStaffCanPerformTask: 'このタスクが実行できるスタッフが登録されていません',
          availableToday: (time: string) => `配置可能 ${time}`,
          notAvailableToday: '配置不可'
        }
      },
      roster: {
        noRoster: 'この日のシフトは作成されていません。作成しますか?',
        create: 'シフトを作成する',
        createInProgress: 'シフト作成中です',
        unsaved: '保存されていない変更があります。変更を破棄してよろしいですか?',
        noStaff: '従業員がいません',
        budgetStats: {
          totalHq: '日割合計(h)',
          totalRostered: '配置合計(h)',
          monthlyDelta: '月間差異(h)',
          locationForecast: '日割予算(h)',
          rosteredBudget: '配置時間(h)',
          deltaTime: '日別差異(h)',
          totalHqAllocatedBudget: '月間予算(h)',
          monthlyLocationForecast: '日割合計(h)',
          monthlySales: '売上予算 (千円)',
          dailySales: '日割売上予算 (千円)'
        },
        selectTemplate: 'シフトパターンを選択',
        changeTemplateWarning: 'この日シフトを削除し、選択したシフトパターンに置き換えます。よろしいですか?',
        deleteReassignedShiftWarning: '派遣元の店舗にスタッフ登録の解除を依頼してください。',
        templates: {
          label: 'シフトパターン登録',
          saveAsNew: 'お気に入りに登録',
          saveAsNewTitle: 'シフトパターン名を入力してください',
          replaceExisting: '他シフトパターンへ貼付け',
          replaceExistingTitle: '貼付け先のシフトパターンを選択してください',
          replace: '貼付け',
          replaceTemplate: '上書き シフトパターン登録',
          chooseTemplate: 'シフトパターンの保存先を選択する',
          replaceWarning: (name: string) =>
            `シフトパターン "${name}" は既に存在します。既存のシフトパターンを上書きしますか?`,
          maxTemplatesLimitReached: (limit: number) =>
            `お気に入りシフトパターンの登録が上限"${limit}"に達しました。不要なシフトパターンを削除するか、既存のシフトパターンに貼付けしてください。`,
          tooltip: {
            templateName: (name: string) => `としてインポートされたシフトパターン ${name}`,
            templateVersion: (version: string) => `バージョン ${version}`
          }
        },
        publish: '公開',
        published: '公開済み',
        lastSaved: '最終更新日',
        lastPublished: '公開済み',
        notifyVacant: '空きシフトを従業員へ通知',
        deleteModal: {
          title: '名簿の削除',
          message: 'この名簿を削除してもよろしいですか?'
        },
        saveWorkInProgressModal: {
          title: '編集の保存',
          message: '勤務が設定されていない従業員が存在します。このまま保存しますか？'
        },
        tooltip: {
          noneAssigned: 'このシフトにはスタッフが割り当てられていません。',
          probationary: (date: string) => `新人期間 ${date}まで`,
          foreignStudent: '留学生',
          canMentor: 'シスター',
          requiresMentor: '新人',
          notAdult: '18歳未満',
          ageNotSpecified: '生年月日未設定',
          leaving: (date: string) => `${date}に退社`,
          left: (date: string) => `${date}退社`,
          management: '(管理レベル)',
          templateName: (name: string) => `${name}シフトパターンから作成`,
          templateVersion: (version: string) => `バージョン ${version}`,
          unableToGetTemplate: 'テンプレートデータが利用できません',
          lastUpdated: '最終更新日時:'
        },
        noRosterCreated: 'この日のシフトは作成されていません。',
        print: {
          title: (date: string, location: string) => `${location}: 日別シフト ${date}`,
          fileName: (date: string, location: string) => `日別シフト-${location}-${date}.pdf`
        },
        errors: {
          employeeAlreadyAssigned: (employeeName: string, employeeId?: string) =>
            `${employeeName} (${employeeId ?? ''})は既にシフトに割り当てられています`
        },
        noNotes: 'この名簿日のメモはありません.',
        rosterHistory: '名簿履歴',
        shiftLockedError: '「このシフトはロックされています。」別の従業員に変更する前にロックを解除してください'
      },
      templates: {
        noTemplates: 'この場所にはシフトパターンが定義されていません。',
        creatingTemplate: 'シフトパターンの設定',
        selectRole: '従業員種別',
        unsaved: '保存されていない変更があります。変更を破棄してよろしいですか?',
        createReplaceUnsaved: '変更内容がすべて保存されていません。以前保存した状態をコピーしてもよろしいですか?',
        clearTemplate: 'シフトパターンをクリア',
        contractTypes: {
          shopManager: '01店長',
          deputyShopManager: '02副店長',
          fullTime: '03正社員',
          partTime: '04パートナー',
          reducedHours: '05時短',
          zeroHours: '06セールススタッフ',
          casual: '07仮枠アカウント',
          student: '09学生'
        },
        myTemplates: {
          label: 'お気に入りシフトパターン',
          createNewTemplate: '新しいシフトパターン',
          noTemplates: 'シフトパターンはまだ作成されていません。',
          nameRequired: 'シフトパターン名は必須です。',
          nameExists: 'シフトパターン名はすでに存在します。',
          addModal: {
            createNewTemplateTitle: '新しいシフトパターンを作成する',
            enterTemplateName: 'シフトパターン名を入力してください',
            generalError: 'シフトパターンを作成できません'
          },
          editModal: {
            title: 'カスタムシフトパターンを編集する',
            editTemplateName: 'シフトパターン名の編集',
            generalError: 'シフトパターンを編集できません'
          },
          deleteModal: {
            title: 'カスタムシフトパターンを削除する',
            deleteConfirmation: (name: string) => `[削除] ボタンをクリックして、${name} シフトパターンを削除します。`,
            generalError: 'シフトパターンを削除できません',
            inUseError: 'シフトパターンは使用中です - 削除できません'
          },
          limitModal: {
            title: 'シフトパターンの制限に達しました',
            info: (max: number) =>
              `カスタム シフトパターンの数が ${max}つの制限に達しました。新しいシフトパターンを追加する前に、既存のシフトパターンを削除してください。`
          }
        },
        shopTemplates: '曜日別シフトパターン',
        libraryTemplates: '目標店シフトパターン',
        library: {
          notPermitted: 'このページを閲覧できるのはシフトパターン ライブラリアンだけです。',
          noneCreated: 'まだライブラリ シフトパターンを作成していません。',
          create: '新しい目標店シフトパターンの作成',
          importModal: {
            title: '目標店シフトパターンのインポート',
            selectTemplate: 'インポートする目標店シフトパターンを選択してください。',
            pleaseSelect: '選んでください',
            alreadyImported: 'そのシフトパターンはすでにインポートされています',
            mustSelect: 'シフトパターンを選択する必要があります。',
            noTemplates: 'ライブラリテンプレートが定義されていません',
            noSearchResults: '検索に一致するテンプレートはありません。',
            placeholder: '名前またはタグでテンプレートを検索します'
          }
        },
        noNotes: 'このシフトパターンにはメモはありません。',
        templateHistory: 'シフトパターン履歴',
        importNotes: (dateString: string, libraryName: string) =>
          `シフトパターンは ${dateString} に ${libraryName} としてインポートされました。` as string,
        version: 'バージョン',
        tags: {
          label: 'タグ',
          description: 'テンプレートにタグを追加して、検索しやすくすることができます。',
          placeholder: 'タグの名前を入力してください',
          otherTags: 'その他のタグ',
          andMore: (count: number) => `あと ${count} 個`,
          none: 'タグが定義されていません'
        }
      },
      summary: {
        scratchTotal: `必要人数`,
        totalActualEmployee: `配置人数`,
        totalTime: '時間合計'
      },
      employeesDropdown: {
        column: {
          status: '配置可否',
          name: '従業員名',
          timeAvailable: '勤務可能時間',
          allocation: '配置状況',
          role: '従業員種別',
          skills: 'スキル',
          tasks: 'タスク',
          taskNames: '可能なタスク',
          holidays: '月間休日数',
          attendances: '月間出勤日数',
          workingHours: '月間総労働時間'
        },
        employeeStatus: {
          available: '配置可能',
          partly_available: '配置要相談',
          unavailable: '配置不可'
        },
        Allocation: {
          allocated: '配置済',
          notAllocated: '未配置'
        },
        availabilities: {
          header: 'ヘルプ申請可能店舗一覧',
          footer: '事前にヘルプを申請する店舗の担当者にご連絡のうえ、ヘルプを申請してください。',
          action: 'ヘルプ申請',
          notFound: 'ヘルプ申請可能店舗はありません。'
        },
        select: '選択',
        myLocation: 'この店舗で勤務可能な従業員',
        fromOtherLocations: '勤務可能な他店舗の従業員',
        timeRequired: '勤務時間',
        waitingAllocation: 'ヘルプ申請中',
        noStaffAvailable: '勤務可能な従業員が見つかりません',
        noStaffAvailableFromOtherLocations: '勤務可能な他店舗の従業員が見つかりません'
      },
      staffAllocation: {
        sendStaffToAnotherLocation: 'ヘルプ派遣',
        selectShiftsAndLocations: 'シフトと勤務地を選択',
        noNeedOfAllocation: 'ヘルプを要請している店舗はありません。',
        staffRequest: 'スタッフの要望'
      },
      changeTimes: {
        startTime: '開始時間',
        endTime: '終了時間',
        startError: (time: string) => `開始時間を変更できません: ${time} から始まるシフトがあります`,
        endError: (time: string) => `終了時刻は変更できません: ${time} に終了するシフトがあります`,
        openBeforeStartError: '開始時刻を開始時刻より前にすることはできません',
        closeAfterEndError: '終了時間を終了時間以降にすることはできません',
        closeBeforeOpenError: '閉店時間は開店時間以降でなければなりません',
        lengthError: (hours: number) => `1 日は少なくとも ${hours} 時間をカバーする必要があります。`
      },
      changeOpenClosingTimes: {
        openTime: '開店時間',
        closeTime: '閉店時間'
      },
      taskCapability: {
        available: `このタスクを実行できます`,
        partlyAvailable: `このタスクが実行できません`,
        unavailable: `必要なスキルを持ったスタッフがいない`
      }
    },
    budget: {
      rows: {
        financial: '売上予算(千円)',
        hqAllocated: '月間予算(h)',
        locationForecast: '日割予算(h)',
        rosteredBudget: '配置時間(h)',
        deltaTime: '差異時間(h)',
        needStaff: 'ヘルプ要請',
        totalEmployees: '出勤従業員数'
      },
      vacantShifts: {
        title: (count: number) => `空きシフト (${count.toString()})`
      },
      noTargets: 'この日付のターゲットデータはインポートされていません。',
      unsavedChanges: '保存されていない変更があります。変更を破棄してよろしいですか?',
      filePrefix: '予算',
      uploadBudget: '予算を登録',
      csvHeader: {
        shopId: '店舗番号',
        shopName: '店舗名',
        date: '日付',
        locationForecast: '日割予算(h)',
        dailySalesTarget: '日割売上予算(千円)',
        rosterHours: '配置時間(h)'
      }
    },
    summary: {
      monthlyTotal: '月別配置',
      max: '上限',
      min: '下限',
      periodTotal: '累計',
      employee: '従業員',
      monthly: '月別配置',
      weekly: '週別配置',
      holiday: '休',
      available: '休',
      reassigned: '他店',
      noData: '指定された期間の従業員データが設定されていません。',
      generate: '従業員を自動配置',
      allocateStaff: 'スタッフを割り当てる',
      allocateStaffMessage: 'すべての店長がすでに勤務表を作成していることを確認してください。この処理には時間がかかります。',
      askForConfirmation: '続行しますか？',
      total: '合計',
      publish: 'シフトの公開',
      published: '公開済み',
      lastPublished: '公開済み',
      shiftSummaryFileName: 'シフト',
      taskSummaryFileName: 'タスク',
      notificationTitle: 'シフト公開のお知らせ',
      notificationMessage: 'シフトが公開されました。公開されたシフトをご確認ください',
      states: {
        paid: '有休',
        dayOff: '希望休',
        rosterDayOff: '固定休',
        working: '出勤'
      },
      print: {
        weekly: {
          fileName: (date: string) => `週別シフト-${date}.pdf`,
          fileTitle: (date: string) => ` 週別シフト: ${date}`
        },
        monthly: {
          fileName: (date: string) => `月別シフト-${date}.pdf`,
          fileTitle: (date: string) => `月別シフト: ${date}`
        }
      },
      publishNotification: {
        prefix: 'の名簿',
        success: 'が出版されています。',
        failure: '公開できなかった。'
      },
      resetRosters: {
        buttonLabel: 'シフトのやり直し',
        modal: {
          title: 'シフトのやり直し',
          message: '現在表示している期間のシフトを削除し、最新のシフトパターンを再読み込みします。よろしいですか？'
        },
        error: 'シフトを初期化できませんでした'
      },
      generateConfirmation: (period: string) =>
        `${period} の従業員配置をやり直し、シフトを再作成します。よろしいですか？`,
      compliance: {
        totalHolidays: '月間休日数',
        totalAttendance: '月間出勤日数',
        totalShifts: '月間シフト数',
        totalUnassigned: '月間未割当シフト数',
        showCompliance: 'シフト集計表',
        showMonthly: 'シフト表',
        totalWorkDays: '総労働日数',
        totalRosterShifts: '総シフト数',
        totalRosterUnassigned: '総未割当シフト数',
        advancedLeaveHeader: '休暇・欠勤の合計',
        advancedLeaveTotal: '全体の合計',
        rostered: '出席',
        notRostered: '休日'
      },
      monthlySort: {
        sort: '並び替え',
        item: '項目',
        ascDes: '昇順/降順',
        max: '上限',
        min: '下限',
        periodTotal: '累計',
        shopPosition: '店舗内役職',
        name: '名前',
        employeeId: '従業員ID'
      },
      monthlyTotalTooltip: {
        totals: '合計:',
        employeeRosteredRequiredHeader: '従業員の名簿登録/必須',
        employeeRosteredRequired:
          'この場所の合計数には、この場所で一時的に勤務している他の場所のスタッフも含まれます。',
        monthlyTotalsHeader: '月次合計',
        monthlyTotals:
          'これは、別の場所で働くために一時的に割り当てられている場合も含め、この (自宅) 場所に恒久的に割り当てられているすべてのスタッフの合計です。'
      }
    },
    settings: {
      unsaved: '保存されていない変更があります。変更を破棄してよろしいですか？',
      pages: {
        system: 'システム',
        manageStaff: '従業員',
        manageSkills: 'スタッフのスキル管理',
        availability: {
          name: 'シフト希望一覧',
          print: {
            fileName: (date: string) => `シフト希望一覧-${date}.pdf`,
            fileTitle: (date: string) => `シフト希望一覧: ${date}`
          }
        },
        locations: {
          label: '店舗管理',
          essMessages: '従業員へのお知らせ',
          myLocation: '店舗設定'
        },
        skills: 'スキル',
        tasks: 'タスク',
        personas: '役割の管理',
        advancedLeave: '繰り上げ休暇の管理',
        standardLeave: '標準休暇の管理',
        userAccounts: 'ユーザーアカウントの管理',
        organisationChart: '組織図'
      },
      manageAvailability: {
        availability: 'シフト希望',
        hours: '出勤可能時間',
        rosteredHours: '出勤時間',
        rosteredLocation: '勤務店舗',
        rosteredLocationNotRostered: '勤務なし',
        rosteredLocationHome: (locationId: string, locationName: string) => `自店舗 ${locationId} ${locationName}`,
        rosteredLocationRemote: (locationId: string, locationName: string) => `他店舗 ${locationId} ${locationName}`,
        pattern: '勤務パターンの時間',
        endBeforeStart: '終了時刻は開始時刻より前である必要があります',
        leaveForPeriod: '月間休日数の集計',
        inactiveLeaveToolTip: (leaveName: string) => `${leaveName} 現在無効な休暇タイプです`,
        warnings: {
          criticalWarning: '重大な警告',
          optionalWarning: 'オプションの警告',
          noAvailability: 'ユーザーには、空き時間外のシフトが割り当てられています。',
          partialAvailability: 'ユーザーはこのシフトに部分的にしか参加できません。'
        }
      },
      manageStaff: {
        employeeId: '従業員ID',
        employeeName: '従業員名',
        katakanaName: 'カタカナ名',
        authority: '権限',
        selectPersonas: '権限の選択',
        selectLocations: '場所を選択してください',
        workPattern: {
          headerLabel: '勤務パターン'
        },
        skills: {
          headerLabel: 'スキル'
        },
        mixMax: {
          monthlyHeaderLabel: '毎月',
          weeklyHeaderLabel: '毎週',
          minHours: '下限',
          maxHours: '上限',
          weeklyExceedsMonthly: '週の最大時間が月の最大時間を超えている'
        },
        personnelDetails: {
          headerLabel: '個人情報',
          dateOfBirth: '生年月日',
          hiredDate: '入社日',
          endProbation: '新人期間終了日',
          mentor: 'シスター',
          requiresMentor: '新人',
          leftCompanyDate: '退社日',
          leftCompanyWarning: (name: string, date: string) => `${name} は ${date} 以降の次の日にシフトがあります:`
        },
        location: {
          headerLabel: '場所の詳細',
          assignLocation: '配属店舗',
          shopPosition: '店舗内役職',
          aditionalLocations: '勤務可能店舗'
        },
        role: {
          admin: '管理者',
          seniorManager: 'シニア・マネージャー',
          manager: '店長',
          staff: 'スタッフ'
        },
        resetPassword: 'パスワード再設定',
        resetButton: '再設定',
        deleteUser: '消去',
        deleteButton: '消去',
        deleteCancel: 'キャンセル',
        casual: '仮枠アカウント',
        addCasual: {
          firstName: '名前',
          lastName: '苗字',
          delete: '消去',
          add: '従業員仮枠を追加'
        },
        noNotes: 'この従業員に対するメモはありません.'
      },
      manageAnnouncements: {
        announcement: 'お知らせを追加',
        activeInactive: '非公開／公開',
        title: '件名',
        text: 'お知らせ内容',
        displayDate: '日付表示',
        showAtDate: '日付で表示',
        createdAt: 'で作成された。',
        updatedAt: '更新日時',
        emptyAnnouncementsList: 'お知らせが設定されておりません。',
        unsavedChanges: '保存されていない変更があります。変更を破棄してよろしいですか？'
      },
      location: {
        locationNumber: '所在地番号',
        locationName: '店舗名',
        supervisor: 'SV名',
        companyCode: '会社コード',
        companyName: '会社名',
        divisionName: '部門名',
        brandName: 'ブランド名',
        SVName: 'SV 名',
        prefecture: '都道府県',
        shopName: '店舗名',
        shopCode: '店舗コード',
        openingDate: 'プレオープン日',
        closedDate: '閉店日',
        inventoryDate: '棚卸日',
        shiftRequestDeadline: 'シフト希望締め切り日',
        deadlineESSReminder: 'シフト希望締め切り通知',
        maxLeaveEachDay: '同日休み希望人数上限',
        shopType: '路面店',
        notDefined: '定義されていません'
      },
      manageSkills: {
        skill: 'スキル',
        activeInactive: 'アクティブ／非アクティブ',
        skillName: 'スキル名',
        tooltip: 'ツールチップ',
        emptySkillsList: 'スキルのリストは空です',
        print: {
          staffSkillsOverview: 'スキル一覧',
          skillCapability: 'スキル一覧',
          printBySkill: '印刷(スキル単位)',
          printByStaffName: '印刷(従業員単位)'
        },
        errors: {
          inUse: 'スキルは使用中のため無効化できません',
          optional: (skill: string, task: string) => `スキル ${skill} はタスク ${task} のオプションのスキルです。`,
          mandatory: (skill: string, task: string) => `スキル ${skill} はタスク ${task} の必須スキルです`
        },
        export: {
          success: 'スタッフスキルCSVが正常にエクスポートされました'
        },
        import: {
          success: (count: number) => `${count} 人のスタッフがスキルを更新しました。`
        }
      },
      manageTasks: {
        task: 'タスク',
        activeInactive: 'アクティブ／非アクティブ',
        taskName: 'タスク名',
        displayName: '表示名',
        icon: 'アイコン',
        textColor: '文字色',
        colors: 'カラー',
        defaultDuration: 'デフォルトの持続時間（分）',
        wholeShift: 'シフト全体',
        supplementaryTask: '補足タスク',
        includeInSummaryTotals: '月次集計に含める',
        calculateWorkingHours: '労働時間の計算',
        skill: '必須スキル',
        optionalSkill: 'オプションスキル',
        selectSkills: 'スキルを選択する',
        emptyTasksList: 'タスクのリストは空です！',
        activeTasks: 'アクティブタスク',
        inactiveTasks: '非アクティブタスク',
        yes: 'Y',
        no: 'N',
        printFileName: (organisationName: string, date: string) => `${organisationName}-タスク-${date}.pdf`,
        errors: {
          inUse: 'タスクは使用中のため非アクティブ化できません',
          shopTemplate: (task: string) => `タスク ${task} は現在、次のショップ テンプレートに割り当てられています。`,
          libraryTemplate: (task: string) =>
            `タスク ${task} は現在、次のライブラリ テンプレートに割り当てられています。`
        }
      },
      managePersonas: {
        persona: '役割',
        personaName: 'ロール名',
        admin: {
          name: '管理者のみ',
          tooltip: `このペルソナを設定/削除できるのは管理者のみです。`
        },
        management: {
          name: '管理',
          tooltip: `ユーザーがマネージャーとして行動できるかどうか。 「管理者の立会い必須」の設定に関するものです。`
        },
        templateLibrarian: {
          name: 'シフトパターンライブラリアン',
          tooltip: `ユーザーがシフトパターン ライブラリを更新できるかどうか。`
        },

        emptyList: 'この役割のリストは空です'
      },
      manageLeave: {
        active: 'アクティブ/非アクティブ',
        leaveUnavailabilityType: '休暇/欠勤タイプ',
        displayName: '表示名',
        systemCode: '外部システムコード',
        colors: '色',
        measurementUnits: '測定単位',
        units: {
          days: '日々',
          hours: '時間'
        },
        maximumTotal: '最大合計',
        minimumTotal: '最小合計',
        level: '警告/必須',
        maximumConsecutive: '最大連続数',
        canOverride: '管理者は上書きできる',
        emptyList: '休暇の種類のリストが空です。',
        leaveType: '休暇の種類',
        per: 'あたり',
        period: {
          rosterPeriod: '名簿期間',
          year: '年',
          month: '月',
          day: '日',
          week: '週'
        },
        warning: '警告',
        mandatory: '必須',
        override: '管理者は上書きできる',
        leaveUnavailabilityTypeToolTip: 'この名前は、必要に応じてエクスポート データで使用されます。',
        displayNameToolTip:
          'これは、ESS を含む UI に表示される名前です。 UI に表示するのに十分なスペースがない場合、最初の数文字が切り捨てられます。',
        systemCodeTooltip: '外部の人事システムに統合するためのコード。 SNPS シフトでは使用されません。',
        maximumTotalTooltip:
          '無効 = 最大値を超えた場合に警告し、合計を送信できます。有効 = ESS ユーザーは最大合計を超えることはできず、合計を送信できません',
        maximumConsecutiveToolTip: '現在ハードコードされた値ですが、現在は強制されていません',
        availableInEss: 'ESS で利用可能でしょうか?',
        alwaysShown: 'N/A 常に表示'
      },
      system: {
        desktopAppBehaviour: {
          label: 'デスクトップアプリの動作',
          title: 'システムタブを強調表示する',
          description: '外観と色を設定する'
        },
        customerSpecific:{
          label: '顧客特定',
          allocateStaff: 'スタッフ割り当てボタンを有効にする',
          allocateStaffDescription: 'この設定はSUNDI用です',
        },
        orgName: {
          title: '展示会社・団体',
          description: '右上に表示される内容を定義します',
          options: {
            company: '会社',
            division: '分割',
            brand: 'ブランド',
            custom: 'カスタム名'
          },
          default: '組織名'
        },
        roster: {
          title: '一般',
          settings: {
            rosterMonthStart: {
              title: '月の開始日',
              description: '月の開始日を設定'
            },
            rosterWeekStart: {
              title: '週表示の開始日',
              description: '週の開始日を定義する'
            },
            defaultStartEnd: {
              title: 'デフォルトのシフトパターン期間',
              description: 'シフトパターンの作成時に使用されるデフォルトの開始時間と終了時間',
              startTime: '開始時間 まで',
              endTime: '終了時間'
            },
            futureAvailabilityMonths: {
              title: 'シフトの表示範囲',
              description: 'シフトの表示範囲を設定',
              monthCount: (count: number) => `${count}ヶ月`
            },
            includeBreaks: {
              title: '配置人数合計に休憩中の従業員を含める',
              description: '有効にすると、配置人数の合計に休憩中の従業員を含めて計算'
            },
            breakOffset: {
              title: '休憩オフセット',
              description: '一日の最初または最後に休憩時間を設定できます',
              firstBreak: '最初の休憩がシフト開始から何分後に発生するかを設定します',
              lastBreak: 'シフト終了の何分前に最後の休憩を設定します'
            },
            holidayRegion: {
              title: '祝日地域',
              description: 'どの地域の祝日を表示するか',
              options: {
                none: 'なし',
                japan: '日本'
              }
            },
            minShiftDuration: {
              title: '最短シフト期間',
              description: '最短シフト時間。シフトをこの値より短くすることはできません',
              minShiftCannotExceedDefaultDuration: '最小シフト時間をデフォルトの期間より長くすることはできません'
            },
            defaultShiftDuration: {
              title: 'デフォルトのシフト期間',
              description: 'シフトパターンと日次名簿の新しいシフトのデフォルト時間'
            },
            maxTimePeriods: {
              title: '各名簿期間で利用可能な期間の最大数',
              description: '従業員を空き時間として指定できる期間の数 (ESS およびスタッフの空き状況の管理)',
              count: (number: number) => `${number}シフト`
            },
            availabilityDeadline: {
              title: '来月のシフト希望提出締切',
              description: '従業員が来月のシフト希望を提出できる締切りを設定',
              toggle: 'リマインダーを自動的に送信する',
              notificationDate: '通知日',
              notificationTime: '通知時間'
            },
            validation: {
              title: '名簿編集の検証',
              managementAttendance: {
                title: '管理者の出席が必要',
                description: 'マネージャーまたは副マネージャーは毎日（最小限のシフト期間中）出席する必要があります。'
              },
              mandatorySkills: {
                title: '必須スキルを強制する',
                description: '必須のスキル要件は無視されます'
              }
            }
          }
        },
        personnelDetails: {
          title: '人事詳細の編集',
          description: '人事日付を読み取り専用ではなく編集可能にします',
          editable: {
            dateOfBirth: '生年月日',
            hiredDate: '採用日',
            leftDate: '退社日',
            endProbDate: '試用期間終了日'
          },
          level: {
            view: 'ビュー',
            hide: '隠れる',
            edit: '編集'
          }
        },
        lastOfMonth: '最後',
        optional: 'オプション'
      },
      admin: {
        title: 'スーパー管理者設定 - SNPS のみで使用する',
        useWithCaution: '使用には注意が必要',
        settings: {
          minimumIncrement: {
            title: 'シフトの時間単位',
            description: 'シフトの編集が可能な最小単位',
            minutes: (number: number) => `${number}分`
          },
          canDeleteRosters: {
            title: '名簿を削除できます',
            description: '日次名簿を削除できるかどうか'
          },
          displayEmployeeIds: {
            title: '従業員 ID を表示する',
            description: '従業員 ID を名前と一緒に表示する'
          },
          minStaffHours: {
            title: 'スタッフの最低勤務時間',
            description: 'スタッフメンバーが毎月働かなければならないデフォルトの最小時間数'
          },
          maxStaffHours: {
            title: '従業員の最大勤務時間',
            description: 'スタッフメンバーが毎月勤務できるデフォルトの最大時間数'
          },
          maxCustomTemplates: {
            title: 'カスタムシフトパターンの最大数',
            description: '顧客が作成できるカスタム シフトパターンの数'
          },
          splitShifts: {
            sectionName: '分割シフト',
            maxNumber: {
              title: '最大シフト数',
              description:
                '特定の名簿期間において、従業員あたり 1 ～ 3 シフト。 1 シフトは、分割シフトが無効になっていることを意味します。',
              count: (number: number) => `${number}シフト`
            },
            minTimeBetween: {
              title: 'シフト間に許可される最小時間',
              description: 'この量未満の場合は、休憩を伴うシフトを意味します。'
            }
          },
          reassignment: {
            sectionName: '他店舗への派遣',
            model: {
              title: '他店舗への派遣モデル',
              onRequestModel: 'オンリクエスト・モデル',
              pullModel: 'プル・モデル',
              disabled: '無効'
            },
            locations: {
              title: 'スタップが勤務できる店舗',
              description:
                'スタッフが勤務できる店舗の範囲を定義します。「指定された店舗」では組織内のすべての店舗が対象になります。',
              allLocations: '同部門の店舗に限定',
              allocatedLocations: '指定された店舗'
            }
          }
        },
        dataCleanUp: {
          title: 'データのクリーンアップ',
          deleteAllRosters: {
            title: 'すべての名簿を削除する',
            confirmationHeader: 'すべてのロスターを削除する',
            confirmationMessage:
              'すべての名簿データを削除しようとしています。この操作は元に戻せません！まだ続けますか？'
          },
          deleteAllTemplates: {
            title: 'すべてのシフトパターンを削除する',
            subtitle: '注：シフトパターンを削除する前に、名簿を削除してください！',
            confirmationHeader: 'すべてのシフトパターンを削除する',
            confirmationMessage:
              'すべてのシフトパターンデータを削除しようとしています。この操作は元に戻せません！まだ続けますか？'
          },
          deleteAllAvailability: {
            title: 'すべての可用性を削除する',
            confirmationHeader: 'すべてのAVAILABILITYを削除する',
            confirmationMessage:
              'あなたはすべての可用性データを削除しようとしています。この操作は元に戻せません！まだ続けますか？'
          }
        },
        dataExport: {
          title: 'データ エクスポート',
          tenantData: {
            title: 'テナントデータのエクスポート',
            subtitle: 'SNPSシフト汎用形式でエクスポート'
          }
        },
        userMaintenance: {
          title: 'ユーザーメンテナンス',
          resetAllPasswords: {
            title: 'すべてのパスワードをデフォルトにリセットする',
            confirmationHeader: '全ユーザーのPASSWORDSをリセットする',
            confirmationMessage:
              'すべてのユーザーアカウントのパスワードをデフォルトの状態にリセットしようとしている。この操作は元に戻せません！まだ続けますか？'
          }
        },
        leaveManagement: {
          title: '勤怠管理',
          advanced: {
            title: '標準/カスタム',
            description: '標準では勤務不可の分類は使用できません',
            labelOn: 'カスタム',
            labelOff: '標準'
          }
        },
        optimizer: {
          title: 'オプティマイザーパラメータ',
          runtimeDuration: {
            title: 'オプティマイザーの実行時間',
            description: 'オプティマイザが実行される秒数 (ペイロードのアセンブル/逆アセンブルは時間枠に含まれません!)'
          },
          management: {
            title: '経営幹部も出席',
            description: '名簿ごとに少なくとも 1 つのシフトへの出席を強制するという厳しい制約'
          }
        },
        organisationChart: {
          orphanedLocationsHolderName: '孤立した店舗',
          filters: {
            displayCurrent: '現在の従業員を表示',
            displayAll: 'すべての従業員を表示',
            displayPast: '元従業員を表示'
          }
        }
      },
      filter: {
        displayActive: '公開中のお知らせを表示',
        displayAll: 'すべて表示',
        displayInactive: '非公開のお知らせを表示'
      },
      staffFilter: {
        displayCurrent: '現在の従業員を表示',
        displayAll: 'すべての従業員を表示',
        displayPast: '元従業員を表示',
        errors: {
          dataError: 'データエラー: 次の内容を修正してください',
          invalidMonthlyTimes: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}、月間最小/最大時間の無効な時間`,
          invalidWeeklyTimes: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}、週の最小/最大時間の無効な時間s`,
          invalidWorkPatternTimes: (employeeId: string, employeeName: string, day: string) =>
            `${employeeId} ${employeeName}、勤務パターンの時間が無効です ${day}`,
          invalidLeavingDate: (employeeId: string, employeeName: string) =>
            `${employeeId} ${employeeName}、無効な出発日`
        }
      },
      preferences: {
        dailyRoster: {
          sectionTitle: '日別シフト',
          staffPicker: '従業員選択時に表示する列',
          taskDisplay: 'タスクの表示',
          status: '配置可否',
          timeAvailable: '勤務可能時間',
          allocation: '配置状況',
          role: '従業員種別',
          totalHolidays: '月間休日数',
          attendances: '月間出勤日数',
          workingHours: '月間総労働時間',
          tasks: 'タスク',
          capableTasks: '可能なタスク',
          personalization: 'シフト編集',
          ganttGridLines: '案内線の表示',
          ganttGridBarThickness: 'シフトバー端の時間調節エリアの幅の大きさ',
          openClosingTimes: {
            label: '営業時間',
            hidden: '非表示',
            fieldsOnly: '時間設定のみ表示',
            all: '時間設定と案内線の表示'
          },
          gridLines: (value: number) => `${value} 分`
        }
      }
    },
    optimiser: {
      notification: {
        generated: 'のシフト作成が完了しました。',
        progress: 'のシフトを作成中です。',
        queued: 'のジョブがキューに登録されました。',
        deleted: 'のジョブがキューから削除されました。',
        failed: (error: string) => `のシフト作成が失敗しました。（エラーコード：${error}）`,
        rosterFor: 'シフト期間:'
      },
      inProgress: '最適化が進行中です...'
    },
    layout: {
      headerLinks: {
        monthlyBudgets: '予算時間',
        monthlyShifts: '月別シフト',
        weeklyShifts: '週別シフト',
        dailyShifts: '日別シフト',
        templates: 'シフトパターン',
        systemSetup: '設定',
        notifications: 'お知らせ',
        location: '所在地'
      },
      headerMenu: {
        myProfile: '私のプロフィール',
        language: '言語',
        languages: {
          english: '英語',
          japanese: '日本'
        },
        logout: 'ログアウト'
      },
      underConstruction: 'このページはまだ開発中です'
    },
    common: {
      sort: '並び替え',
      print: '印刷',
      printModal: {
        title: '印刷オプション',
        screenView: '画面印刷',
        posterView: '印刷（上限下限なし）'
      },
      upload: 'アップロード',
      download: 'ダウンロード',
      na: '利用不可',
      time: '時間',
      date: '年代',
      submit: '登録',
      cancel: 'キャンセル',
      create: '作成',
      save: '保存',
      publish: '公開',
      edit: '編集',
      delete: '消去',
      deleteAll: 'すべて削除',
      reset: 'やり直す',
      resetAll: 'すべてリセット',
      toBeDeleted: '削除される！',
      close: '閉じる',
      view: '詳細',
      loading: 'ロード...',
      firstName: 'ファーストネーム',
      lastName: '苗字',
      email: 'Eメール',
      phone: '電話',
      phoneNumber: '電話番号',
      pagination: {
        pageXOfY: (page: number, total: number) => `ページ ${page} / ${total}`
      },
      search: '検索',
      mandatory: (field: string) => `${field} が必要です`,
      ok: 'OK',
      continue: '続ける',
      yes: 'はい',
      no: 'いいえ',
      locationName: '店舗名',
      total: '合計',
      employee: '従業員',
      daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
      daysOfWeekMid: ['日', '月', '火', '水', '木', '金', '土'],
      daysOfWeekFull: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '祝日'],
      daysOfWeekFullStartMonday: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日', '祝日'],
      success: '成功',
      error: 'エラーが発生しました',
      select: '未指定',
      jp: 'JP',
      en: 'EN',
      today: '今日',
      notPermitted: 'このアカウントにはこのサイトにアクセスする権限がありません。',
      file: {
        download: '予算を取得',
        upload: '予算を登録',
        selectFile: 'ファイルを選択',
        pleaseSelect: 'ファイルを選択してください',
        downloadShifts: 'シフトを取得',
        downloadSummaries: {
          title: 'サマリーを取得',
          options: {
            shifts: 'シフト',
            tasks: 'タスク'
          }
        }
      },
      totals: {
        max: '上限',
        min: '下限',
        periodTotal: '累計',
        dailyTotal: '拘束',
        actualTotal: '実働',
        break: '休憩',
        monthlyTotal: '月別配置',
        weeklyTotal: '週別配置',
        attendanceTotal: '日別配置'
      },
      enterValidPhone: '有効な電話番号を入力して下さい。',
      enterValidEmail: '正しいメールアドレスを入力してください。',
      severity: {
        critical: '重大警告',
        warning: '警告'
      },
      notesModalTitle: 'ノート',
      notesPrintLabel: 'ノート:',
      export: '輸出',
      import: '輸入',
      set: '設定',
      add: '追加'
    },
    notifications: {
      filter: {
        all: 'すべて',
        roster_generation: 'シフト作成',
        roster_publish: 'シフトの公開',
        application: '応用'
      },
      noNotifications: 'お知らせはありません。',
      push: {
        rosterPublishedTitle: '【シナプスシフト 公開】',
        rosterPublishedMessage: (startDate: string, endDate: string) =>
          `${startDate} - ${endDate}のシフトが公開されました`,
        availabilityDeadlineTitle: 'Availability Deadline',
        availabilityDeadlineMessage:
          'Please ensure your availability for the coming roster period has been entered before close of business tomorrow.'
      },
      leftCompany: (name: string, date: string) =>
        `${name} は ${date} に退職し、退職日以降の日付で名簿に登録されています`,
      willLeaveCompany: (name: string, date: string) =>
        `${name} は ${date} に退職し、退職日以降の日付に登録されます`
    },
    keys: {
      reassigned: '他店',
      unassigned: '未指定'
    },
    colors: {
      black: 'ブラック',
      white: 'ホワイト'
    },
    dropdown: {
      noOptions: '選択肢はない！'
    }
  }
});
